import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { clearAuth } from './authSlice';  // Add this import


export const setNewMessageIndicator = createAsyncThunk(
  'user/setNewMessageIndicator',
  async ({ userId, fromUserId }, { rejectWithValue }) => {
    try {
      await api.post('/messages/set-new-message-indicator', { userId, fromUserId });
      return { userId, fromUserId };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to set new message indicator');
    }
  }
);

export const clearNewMessageIndicator = createAsyncThunk(
  'user/clearNewMessageIndicator',
  async ({ userId, fromUserId }, { rejectWithValue }) => {
    try {
      await api.post('/messages/clear-new-message-indicator', { userId, fromUserId });
      return fromUserId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to clear new message indicator');
    }
  }
);

export const clearAllMessageIndicators = createAsyncThunk(
  'user/clearAllMessageIndicators',
  async (userId, { rejectWithValue }) => {
    try {
      await api.post('/messages/clear-all-message-indicators', { userId });
      return;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to clear all message indicators');
    }
  }
);

export const fetchMatches = createAsyncThunk(
  'user/fetchMatches',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await api.get('/matches/matches', { params: { userId } });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch matches');
    }
  }
);

export const unmatchUser = createAsyncThunk(
  'user/unmatchUser',
  async ({ userId, unmatchUserId }, { rejectWithValue }) => {
    try {
      await api.put('/matches/unmatch', { userId, unmatchUserId });
      return unmatchUserId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to unmatch user');
    }
  }
);

export const blockUser = createAsyncThunk(
  'user/blockUser',
  async ({ userId, blockUserId }, { rejectWithValue }) => {
    try {
      await api.put('/matches/block', { userId, blockUserId });
      return blockUserId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to block user');
    }
  }
);

export const swipeUser = createAsyncThunk(
  'user/swipeUser',
  async ({ userId, swipedUserId, direction }, { rejectWithValue }) => {
    try {
      const response = await api.post('/matches/swipe', { userId, swipedUserId, direction });
      
      const isMatch = response.data.isMatch || false;
      
      let matchedUser = null;
      if (isMatch) {
        const userResponse = await api.get('/users/user', { params: { userId: swipedUserId } });
        matchedUser = userResponse.data;
      }
      
      return { isMatch, matchedUser };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to swipe user');
    }
  }
);

export const resetSwipes = createAsyncThunk(
  'user/resetSwipes',
  async (userId, { rejectWithValue }) => {
    try {
      await api.post('/matches/reset-swipes', { userId });
      return userId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to reset swipes');
    }
  }
);

export const updateUserPreferences = createAsyncThunk(
  'user/updatePreferences',
  async ({ userId, preferences, location }, { rejectWithValue }) => {
    try {
      const response = await api.put('/user/preferences', { userId, preferences, location });
      if (location) {
        localStorage.setItem('userLocation', JSON.stringify(location));
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchNewMatches = createAsyncThunk(
  'user/fetchNewMatches',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await api.get('/matches/new-matches', { params: { userId } });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch new matches');
    }
  }
);

export const clearNewMatch = createAsyncThunk(
  'user/clearNewMatch',
  async ({ userId, matchedUserId }, { rejectWithValue }) => {
    try {
      await api.post('/matches/clear-new-match', { userId, matchedUserId });
      return matchedUserId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to clear new match');
    }
  }
);

export const fetchFullProfile = createAsyncThunk(
  'user/fetchFullProfile',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await api.get('/users/user', { params: { userId } });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch full profile');
    }
  }
);

export const setUserLocation = createAsyncThunk(
  'user/setUserLocation',
  async (location, { getState, rejectWithValue }) => {
    try {
      const { auth } = getState();
      if (!auth.userId) {
        throw new Error('User not authenticated');
      }
      const response = await api.post('/users/update-location', { 
        userId: auth.userId,
        location: {
          type: 'Point',
          coordinates: [location.coordinates[0], location.coordinates[1]]
        }
      });
      localStorage.setItem('userLocation', JSON.stringify(location));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update location');
    }
  }
);


export const logoutUser = createAsyncThunk(
  'user/logout',
  async (_, { dispatch }) => {
    try {
      await api.post('/auth/logout');
      localStorage.removeItem('authToken');
      dispatch(clearAuth());
      dispatch(clearUserState());
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  }
);


const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: null,
    location: null,
    matches: [],
    newMatches: [],
    showNewMatchesModal: false,
    currentMatchIndex: 0,
    onboardingCompleted: false,
    status: 'idle',
    error: null,
    darkMode: false,
    newMessageIndicators: [],
    isOnline: false
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.profile = action.payload;
    },
    setOnlineStatus: (state, action) => {
      state.isOnline = action.payload;
    },
    setOnboardingCompleted: (state, action) => {
      state.onboardingCompleted = action.payload;
    },
    addMatch: (state, action) => {
      state.matches.push(action.payload);
    },
    updateMatches: (state, action) => {
      state.matches = action.payload;
    },
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    setNewMatches: (state, action) => {
      state.newMatches = action.payload;
      state.showNewMatchesModal = action.payload.length > 0;
      state.currentMatchIndex = 0;
    },
    addNewMatch: (state, action) => {
      if (action.payload && action.payload.user_id) {
        const existingMatchIndex = state.newMatches.findIndex(match => match.user_id === action.payload.user_id);
        if (existingMatchIndex === -1) {
          state.newMatches.push({ ...action.payload, seen: false });
          state.showNewMatchesModal = state.newMatches.length > 0;
        } else {
          console.log('Match already exists:');
        }
      } else {
        console.error('Invalid match data:');
      }
    },
    incrementMatchIndex: (state) => {
      if (state.currentMatchIndex < state.newMatches.length - 1) {
        state.currentMatchIndex += 1;
      } else {
        state.currentMatchIndex = 0;
      }
    },
    closeNewMatchesModal: (state) => {
      state.showNewMatchesModal = false;
      state.currentMatchIndex = 0;
      state.newMatches = [];
    },
    clearMatches: (state) => {
      state.matches = [];
    },
    clearUserState: (state) => {
      state.profile = null;
      state.location = null;
      state.matches = [];
      state.newMatches = [];
      state.showNewMatchesModal = false;
      state.currentMatchIndex = 0;
      state.onboardingCompleted = false;
      state.status = 'idle';
      state.error = null;
      state.newMessageIndicators = [];
      state.isOnline = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMatches.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMatches.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.matches = action.payload;
      })
      .addCase(fetchMatches.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(unmatchUser.fulfilled, (state, action) => {
        state.matches = state.matches.filter(match => match.user_id !== action.payload);
      })
      .addCase(blockUser.fulfilled, (state, action) => {
        state.matches = state.matches.filter(match => match.user_id !== action.payload);
        if (state.profile) {
          state.profile.blockedUsers = [...(state.profile.blockedUsers || []), action.payload];
        }
      })
      .addCase(swipeUser.fulfilled, (state, action) => {
        if (action.payload && action.payload.isMatch && action.payload.matchedUser) {
          userSlice.caseReducers.addNewMatch(state, { payload: action.payload.matchedUser });
        }
      })
      .addCase(fetchNewMatches.fulfilled, (state, action) => {
        state.newMatches = action.payload;
        state.showNewMatchesModal = action.payload.length > 0;
        state.currentMatchIndex = 0;
      })
      .addCase(clearNewMatch.fulfilled, (state, action) => {
        const matchIndex = state.newMatches.findIndex(match => match.user_id === action.payload);
        if (matchIndex !== -1) {
          state.newMatches[matchIndex].seen = true;
        }
        const matchToMove = state.newMatches.find(match => match.user_id === action.payload);
        if (matchToMove) {
          state.matches.push(matchToMove);
        }
        if (state.newMatches.every(match => match.seen)) {
          state.showNewMatchesModal = false;
          state.currentMatchIndex = 0;
          state.newMatches = [];
        } else if (state.currentMatchIndex >= state.newMatches.length) {
          state.currentMatchIndex = 0;
        }
      })
      .addCase(resetSwipes.fulfilled, (state) => {
        state.matches = [];
        state.newMatches = [];
      })
      .addCase(updateUserPreferences.fulfilled, (state, action) => {
        if (state.profile) {
          state.profile.preferences = action.payload.preferences;
          if (action.payload.location) {
            state.profile.location = action.payload.location;
            state.location = action.payload.location;
          }
        }
      })
      .addCase(setNewMessageIndicator.fulfilled, (state, action) => {
        if (!state.newMessageIndicators.includes(action.payload.fromUserId)) {
          state.newMessageIndicators.push(action.payload.fromUserId);
        }
      })
      .addCase(clearNewMessageIndicator.fulfilled, (state, action) => {
        state.newMessageIndicators = state.newMessageIndicators.filter(id => id !== action.payload);
      })
      .addCase(clearAllMessageIndicators.fulfilled, (state) => {
        state.newMessageIndicators = [];
      })
      .addCase(fetchFullProfile.fulfilled, (state, action) => {
        const index = state.matches.findIndex(profile => profile.user_id === action.payload.user_id);
        if (index !== -1) {
          state.matches[index] = { ...state.matches[index], ...action.payload };
        }
      })
      .addCase(setUserLocation.fulfilled, (state, action) => {
        state.location = action.payload.location;
        if (state.profile) {
          state.profile.location = action.payload.location;
        }
      })
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          if (action.payload === 'Unauthorized') {
            state.status = 'failed';
            state.error = 'Session expired. Please log in again.';
          }
        }
      );
  }
});

export const {
  setUserProfile,
  setOnboardingCompleted,
  addMatch,
  updateMatches,
  toggleDarkMode,
  setNewMatches,
  addNewMatch,
  incrementMatchIndex,
  closeNewMatchesModal,
  setOnlineStatus,
  clearMatches,
  clearUserState
} = userSlice.actions;

export default userSlice.reducer;