import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Globe } from 'lucide-react';

const LanguageSwitcher = ({ className = '', customStyles = {}, onChange }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: 'en', name: 'EN' },
    { code: 'tr', name: 'TR' },
  ];

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng).then(() => {
      setCurrentLanguage(lng);
      localStorage.setItem('i18nextLng', lng);
      if (onChange) {
        onChange(lng);
      }
    });
    setIsOpen(false);
  };

  const defaultStyles = {
    button: "inline-flex items-center justify-center rounded-full bg-gray-200 text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-2 py-1 text-sm transition duration-300",
    dropdown: "origin-top-right absolute right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50",
    option: "block px-4 py-2 text-sm w-full text-center hover:bg-gray-100 transition duration-150"
  };

  const buttonStyle = customStyles.button || defaultStyles.button;
  const dropdownStyle = customStyles.dropdown || defaultStyles.dropdown;
  const optionStyle = customStyles.option || defaultStyles.option;

  return (
    <div className={`relative inline-block text-left ${className}`}>
      <button
        type="button"
        className={buttonStyle}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Globe className="mr-1 h-4 w-4" />
        {languages.find(lang => lang.code === currentLanguage)?.name || 'EN'}
      </button>

      {isOpen && (
        <div className={dropdownStyle}>
          <div className="py-1" role="menu">
            {languages.map((lang) => (
              <button
                key={lang.code}
                onClick={() => changeLanguage(lang.code)}
                className={`${
                  lang.code === currentLanguage ? 'bg-indigo-100 text-indigo-600' : 'text-gray-700'
                } ${optionStyle}`}
              >
                {lang.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;