// anonymousChatSlice.js
import { createSlice } from '@reduxjs/toolkit';

const anonymousChatSlice = createSlice({
  name: 'anonymousChat',
  initialState: {
    status: 'disconnected',
    country: 'global',
    activeChat: null,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
    resetChat: (state) => {
      state.status = 'disconnected';
      state.activeChat = null;
    },
  },
});

export const { setStatus, setCountry, setActiveChat, resetChat } = anonymousChatSlice.actions;
export default anonymousChatSlice.reducer;