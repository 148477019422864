import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(
    (config) => {
        if (!config.url.includes('/auth/login') && 
            !config.url.includes('/auth/signup') && 
            !config.url.includes('/auth/send-verification-email') &&
            !config.url.includes('/auth/verify-email') &&
            !config.url.includes('/auth/initiate-password-reset') &&
            !config.url.includes('/auth/verify-reset-token') &&
            !config.url.includes('/auth/reset-password')) {
            const token = localStorage.getItem('authToken');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response) {
            console.error('Response error:', error.response.status, error.response.data);
        } else if (error.request) {
            console.error('Request error:', error.request);
        } else {
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

api.initiatePasswordReset = (email) => {
    return api.post('/auth/initiate-password-reset', { email });
};

api.verifyResetToken = (email, token) => {
    return api.post('/auth/verify-reset-token', { email, token });
};

api.resetPassword = (email, token, newPassword) => {
    return api.post('/auth/reset-password', { email, token, newPassword });
};


export default api;