import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuth } from '../redux/slices/authSlice';
import Cookies from 'js-cookie';
import logoWhite from "../images/matchmates-logo-white.svg";
import logo from "../images/matchmates-logo-no-text.svg";
import { LogIn, UserPlus } from 'lucide-react';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Nav = ({ minimal, setShowModal, showModal, setIsSignUp, logoVersion, variant}) => {
  const dispatch = useDispatch();
  const authToken = useSelector(state => state.auth.authToken);

  const { t } = useTranslation();

  const handleLoginClick = () => {
    setShowModal(true);
    setIsSignUp(false);
  };

  const handleSignUpClick = () => {
    setShowModal(true);
    setIsSignUp(true);
  };

  const handleLogout = () => {
    Cookies.remove('userId')
    Cookies.remove('authToken')
    window.location.reload();
  };
  const getNavClasses = () => {
    switch(variant) {
      case 'onboarding':
        return "bg-transparent p-2 sm:p-4";
      default:
        return "bg-transparent p-2 sm:p-4";
    }
  };

  return (
    <nav className={getNavClasses()}>
      <div className="container mx-auto flex flex-wrap justify-between items-center">
        <div className="flex items-center w-full sm:w-auto justify-between mb-2 sm:mb-0">
          <img
            className="logo w-32 sm:w-40 md:w-48"
            src={logoVersion === 'default' ? logo : logoWhite}
            alt="MatchMates logo"
          />
          <div className="sm:hidden">
            <LanguageSwitcher className="text-sm" />
          </div>
        </div>
        <div className="flex items-center space-x-2 sm:space-x-4 w-full sm:w-auto justify-center sm:justify-end">
          <div className="hidden sm:block">
            <LanguageSwitcher className="text-sm" />
          </div>
          {!authToken && !minimal && (
            <>
              <button
                className="bg-white text-purple-600 hover:bg-purple-100 px-3 py-1 sm:px-4 sm:py-2 rounded-full text-sm transition duration-300 flex items-center transform hover:scale-105"
                onClick={handleLoginClick}
                disabled={showModal}
              >
                <LogIn className="mr-1" size={16} />
                {t('auth.logIn')}{' '}
              </button>
              <button
                className="bg-blue-400 hover:bg-yellow-300 text-white hover:text-blue-600 px-3 py-1 sm:px-4 sm:py-2 rounded-full text-sm transition duration-300 flex items-center transform hover:scale-105"
                onClick={handleSignUpClick}
                disabled={showModal}
              >
                <UserPlus className="mr-1" size={16} />
                {t('auth.signUp')}{' '}
              </button>
            </>
          )}
          {authToken && !minimal && (
            <button
              className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 sm:px-4 sm:py-2 rounded-full text-sm transition duration-300 flex items-center transform hover:scale-105"
              onClick={handleLogout}
            >
              <LogIn className="mr-1" size={16} />
              Sign Out
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Nav;