import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, isAuthenticated, onboardingCompleted, onboardingRequired = true }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (onboardingRequired && !onboardingCompleted) {
    return <Navigate to="/onboarding" replace />;
  }

  return children;
};

export default ProtectedRoute;