import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowLeft, ChevronDown } from 'lucide-react';
import logo from "../images/DestyMe-logo.svg"

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState('');
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let currentActiveSection = '';
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.pageYOffset >= sectionTop - 100 && window.pageYOffset < sectionTop + sectionHeight - 100) {
          currentActiveSection = section.id;
        }
      });
      setActiveSection(currentActiveSection);

      // Sticky sidebar logic
      const sidebar = sidebarRef.current;
      const content = contentRef.current;
      const footer = footerRef.current;

      if (sidebar && content && footer) {
        const sidebarHeight = sidebar.offsetHeight;
        const contentHeight = content.offsetHeight;
        const footerTop = footer.offsetTop;
        const scrollPosition = window.pageYOffset;
        const windowHeight = window.innerHeight;

        if (scrollPosition + sidebarHeight + 112 > footerTop) { // 112px is the top offset (28 * 4)
          sidebar.style.position = 'absolute';
          sidebar.style.top = `${footerTop - sidebarHeight - 32}px`; // 32px for some bottom margin
        } else if (scrollPosition > 112) { // Start sticking after scrolling past the top offset
          sidebar.style.position = 'fixed';
          sidebar.style.top = '112px';
        } else {
          sidebar.style.position = 'absolute';
          sidebar.style.top = '112px';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 80,
        behavior: 'smooth'
      });
    }
  };

  const sections = [
    'introduction', 'dataCollection', 'dataUse', 'dataSharing', 'dataSecurity',
    'userRights', 'childrenPrivacy', 'internationalTransfers', 'changes', 'contact'
  ];

  return (
    <div className="min-h-screen bg-gray-50 text-black">
      <nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-20">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="DestyMe Logo" className="w-16 h-16" />
          </Link>
          <h1 className="text-2xl font-bold text-gray-900">{t('privacyPolicy.title')}</h1>
        </div>
      </nav>

      <div className="container mx-auto px-4 pt-24 pb-12 flex flex-col md:flex-row" ref={contentRef}>
        <aside 
          ref={sidebarRef}
          className="hidden md:block md:w-64 overflow-y-auto bg-white rounded-lg shadow-lg p-4"
          style={{ position: 'absolute', top: '112px' }}
        >
          <h2 className="text-xl font-semibold mb-4 text-gray-900">{t('privacyPolicy.title')}</h2>
          <ul>
            {sections.map((section) => (
              <li key={section} className="mb-2">
                <button
                  onClick={() => scrollToSection(section)}
                  className={`text-left w-full py-2 px-3 rounded-md transition duration-300 ${
                    activeSection === section
                      ? 'bg-gray-200 text-gray-900'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  {t(`privacyPolicy.sections.${section}`)}
                </button>
              </li>
            ))}
          </ul>
        </aside>

        <main className="w-full md:ml-72 flex-grow mt-8 md:mt-0">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <p className="text-sm text-gray-500 mb-6">{t('privacyPolicy.lastUpdated')} 16.08.2024 </p>
            <p className="mb-8 text-lg text-gray-800">{t('privacyPolicy.introduction')}</p>

            {sections.map((section) => (
              <section key={section} id={section} className="mb-12">
                <h2 className="text-2xl font-bold mb-4 text-gray-900">
                  {t(`privacyPolicy.sections.${section}`)}
                </h2>
                <div className="prose max-w-none text-gray-800">
                  <p>{t(`privacyPolicy.${section}Text`)}</p>
                </div>
              </section>
            ))}
          </div>
        </main>
      </div>

      <footer ref={footerRef} className="bg-transparent text-black p-6 mt-16">
        <div className="container mx-auto flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-6">
          <Link to="/" className="text-xs hover:underline transition-all">
            {t('general.backToHome')}
          </Link>
          <div className="w-px h-4 bg-black bg-opacity-30 hidden sm:block"></div>
          <Link to="/terms-of-service" className="text-xs hover:underline transition-all">
            {t('homePage.termsOfService')}
          </Link>
          <div className="w-px h-4 bg-black bg-opacity-30 hidden sm:block"></div>
          <Link to="/privacy-policy" className="text-xs hover:underline transition-all">
            {t('settings.privacyPolicy')}
          </Link>
        </div>
      </footer>

      <div className="fixed bottom-4 right-4">
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="bg-gray-800 text-white p-3 rounded-full shadow-lg hover:bg-gray-700 transition duration-300"
        >
          <ChevronDown size={24} className="transform rotate-180" />
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;