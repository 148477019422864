import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMatches, unmatchUser, blockUser, clearNewMessageIndicator, clearAllMessageIndicators, clearMatches} from '../../redux/slices/userSlice';
import ChatBox from './ChatBox';
import { UserMinus, Shield, Loader, MapPin, X } from 'lucide-react';
import LoadingSkeleton from './LoadingSkeleton';
import { getSocket } from '../../utils/socketManager';
import ProfilePreviewModal from './ProfilePreviewModal';
import api from '../../utils/api';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, action, userName }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">Confirm {action}</h2>
        <p>Are you sure you want to {action.toLowerCase()} {userName}?</p>
        {action === 'Block' && (
          <p className="mt-2 text-sm text-gray-600">
            Blocking will unmatch you and prevent this user from appearing in your match pool.
          </p>
        )}
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const MessagesTab = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.profile);
  const matches = useSelector(state => state.user.matches);
  const [messages, setMessages] = useState({});
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [actionUserId, setActionUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const newMessageIndicators = useSelector(state => state.user.newMessageIndicators);
  const userId = useSelector(state => state.auth.userId);

  const [selectedProfilePreview, setSelectedProfilePreview] = useState(null);
  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);

  const fetchMatchesData = useCallback(async () => {
    if (userId) {
      try {
        setLoading(true);
        dispatch(clearMatches());
        await dispatch(fetchMatches(userId)).unwrap();
      } catch (error) {
        console.error('Error fetching matches:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId) {
      dispatch(clearAllMessageIndicators(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    fetchMatchesData();
  }, [fetchMatchesData]);

  useEffect(() => {
    const socket = getSocket();
    
    if (socket) {
      const handleNewMessage = (message) => {
        setMessages(prevMessages => {
          const conversationId = [message.from_userId, message.to_userId].sort().join('-');
          return {
            ...prevMessages,
            [conversationId]: [...(prevMessages[conversationId] || []), message]
          };
        });
      };

      socket.on('new messages tab message', handleNewMessage);

      return () => {
        socket.off('new messages tab message', handleNewMessage);
      };
    }
  }, []);

  const openConfirmationModal = (action, userId) => {
    setActionType(action);
    setActionUserId(userId);
    setModalOpen(true);
  };

  const handleSelectMatch = (match) => {
    setSelectedMatch(match);
    dispatch(clearNewMessageIndicator({ userId, fromUserId: match.user_id }));
  };

  const handleProfilePreview = async (match) => {
    setIsLoadingProfile(true);
    try {
      const response = await api.get('/users/user', {
        params: { userId: match.user_id }
      });
      
      const fullProfileData = response.data;
      
      if (!fullProfileData.distance && match.distance) {
        fullProfileData.distance = match.distance;
      }
      
      setSelectedProfilePreview(fullProfileData);
      setIsProfilePreviewOpen(true);
    } catch (error) {
      console.error('Error fetching full profile:', error);
      setSelectedProfilePreview(match);
      setIsProfilePreviewOpen(true);
    } finally {
      setIsLoadingProfile(false);
    }
  };

  const handleConfirmAction = async () => {
    if (actionType === 'Unmatch') {
      await dispatch(unmatchUser({ userId, unmatchUserId: actionUserId }));
    } else if (actionType === 'Block') {
      await dispatch(blockUser({ userId, blockUserId: actionUserId }));
    }
    setModalOpen(false);
    setSelectedMatch(null);
  };

  const sendMessage = useCallback((message) => {
    const socket = getSocket();
    if (socket) {
      socket.emit('messages tab chat', message);
      setMessages(prevMessages => {
        const conversationId = [message.from_userId, message.to_userId].sort().join('-');
        return {
          ...prevMessages,
          [conversationId]: [...(prevMessages[conversationId] || []), message]
        };
      });
    }
  }, []);

  if (!user) {
    return <div className="flex items-center justify-center h-full">Loading user data...</div>;
  }
  
  return (
    <div className="relative h-full bg-white rounded-3xl shadow-xl overflow-hidden">
      {/* Mobile and Tablet Layout (below 768px) */}
      <div className="md:hidden h-full flex flex-col">
        <div className="h-full overflow-y-auto" style={{ paddingBottom: 'env(safe-area-inset-bottom, 84px)' }}>
          <h2 className="text-2xl font-bold p-4 pt-20">Matches</h2>
          <div className="flex-grow overflow-y-auto">
            {loading ? (
              <div className="space-y-4 p-4">
                {[...Array(5)].map((_, index) => (
                  <LoadingSkeleton key={index} />
                ))}
              </div>
            ) : matches && matches.length > 0 ? (
              <ul className="divide-y divide-gray-200">
                {matches.map((match, index) => (
                  <li
                    key={`${match.user_id}-${index}`}
                    className={`p-4 cursor-pointer hover:bg-gray-100 ${selectedMatch?.user_id === match.user_id ? 'bg-gray-200' : ''} flex justify-between items-center`}
                    onClick={() => handleSelectMatch(match)}
                  >
                    <div className="flex items-center flex-grow">
                      <img 
                        src={match.media && match.media[0] ? match.media[0].url : '/default-avatar.png'} 
                        alt={match.first_name} 
                        className="w-10 h-10 rounded-full mr-3 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleProfilePreview(match);
                        }}
                      />
                      <div>
                        <span className="font-semibold">{match.first_name}</span>
                        {match.distance && (
                          <div className="text-xs text-gray-500 flex items-center">
                            <MapPin size={12} className="mr-1" />
                            {match.distance} km away
                          </div>
                        )}
                      </div>
                      {Array.isArray(newMessageIndicators) && newMessageIndicators.includes(match.user_id) && (
                        <div className="ml-auto w-3 h-3 bg-green-500 rounded-full"></div>
                      )}
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openConfirmationModal('Unmatch', match.user_id);
                        }}
                        className="text-red-500 hover:text-red-700"
                        title="Unmatch"
                      >
                        <UserMinus size={20} />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openConfirmationModal('Block', match.user_id);
                        }}
                        className="text-gray-500 hover:text-gray-700"
                        title="Block"
                      >
                        <Shield size={20} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="flex items-center justify-center h-full text-gray-500">
                No matches found
              </div>
            )}
          </div>
        </div>
        {/* Mobile Chat Slider */}
        <div 
          className={`fixed inset-x-0 top-0 bg-white transition-transform transform ${selectedMatch ? 'translate-y-0' : 'translate-y-full'}`} 
          style={{ height: 'calc(100% - 44px - 84px)', top: '64px' }}
        >
          {selectedMatch && (
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between p-4 border-b">
                <h2 className="text-xl font-semibold">{selectedMatch.first_name}</h2>
                <button 
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => setSelectedMatch(null)}
                >
                  <X size={24} />
                </button>
              </div>
              <div className="flex-grow overflow-hidden">
                <ChatBox 
                  user={user} 
                  match={selectedMatch} 
                  messages={messages[`${user.user_id}-${selectedMatch.user_id}`.split('-').sort().join('-')] || []}
                  sendMessage={sendMessage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
  
      {/* Desktop Layout (above 768px) */}
      <div className="hidden md:flex h-full">
        <div className="w-1/3 border-r flex flex-col">
          <h2 className="text-2xl font-bold p-4">Matches</h2>
          <div className="flex-grow overflow-y-auto">
            {loading ? (
              <div className="space-y-4 p-4">
                {[...Array(5)].map((_, index) => (
                  <LoadingSkeleton key={index} />
                ))}
              </div>
            ) : matches && matches.length > 0 ? (
              <ul className="divide-y divide-gray-200">
                {matches.map((match, index) => (
                  <li
                    key={`${match.user_id}-${index}`}
                    className={`p-4 cursor-pointer hover:bg-gray-100 ${selectedMatch?.user_id === match.user_id ? 'bg-gray-200' : ''} flex justify-between items-center`}
                    onClick={() => handleSelectMatch(match)}
                  >
                    <div className="flex items-center flex-grow">
                      <img 
                        src={match.media && match.media[0] ? match.media[0].url : '/default-avatar.png'} 
                        alt={match.first_name} 
                        className="w-10 h-10 rounded-full mr-3 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleProfilePreview(match);
                        }}
                      />
                      <div>
                        <span className="font-semibold">{match.first_name}</span>
                        {match.distance && (
                          <div className="text-xs text-gray-500 flex items-center">
                            <MapPin size={12} className="mr-1" />
                            {match.distance} km away
                          </div>
                        )}
                      </div>
                      {Array.isArray(newMessageIndicators) && newMessageIndicators.includes(match.user_id) && (
                        <div className="ml-auto w-3 h-3 bg-green-500 rounded-full"></div>
                      )}
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openConfirmationModal('Unmatch', match.user_id);
                        }}
                        className="text-red-500 hover:text-red-700"
                        title="Unmatch"
                      >
                        <UserMinus size={20} />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openConfirmationModal('Block', match.user_id);
                        }}
                        className="text-gray-500 hover:text-gray-700"
                        title="Block"
                      >
                        <Shield size={20} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="flex items-center justify-center h-full text-gray-500">
                No matches found
              </div>
            )}
          </div>
        </div>
        <div className="w-2/3">
          {selectedMatch ? (
            <ChatBox 
              user={user} 
              match={selectedMatch} 
              messages={messages[`${user.user_id}-${selectedMatch.user_id}`.split('-').sort().join('-')] || []}
              sendMessage={sendMessage}
            />
          ) : (
            <div className="h-full flex items-center justify-center">
              <p className="text-gray-500">Select a match to start chatting</p>
            </div>
          )}
        </div>
      </div>
  
      <ConfirmationModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirmAction}
        action={actionType}
        userName={matches?.find(m => m?.user_id === actionUserId)?.first_name}
      />
     {isLoadingProfile && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <Loader className="animate-spin text-white" size={48} />
        </div>
      )}
      <ProfilePreviewModal 
        user={selectedProfilePreview}
        onClose={() => setIsProfilePreviewOpen(false)}
        isOpen={isProfilePreviewOpen}
      />
    </div>
  );
};

export default MessagesTab;