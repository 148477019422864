import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  clearAllMessageIndicators, 
  addMatch, 
  fetchMatches, 
  setUserProfile, 
  fetchNewMatches, 
  clearNewMatch, 
  addNewMatch, 
  incrementMatchIndex, 
  closeNewMatchesModal,
  setNewMatches,
  logoutUser
} from '../redux/slices/userSlice';
import { 
  setStatus as setAnonymousChatStatus,
  setActiveChat,
  resetChat
} from '../redux/slices/anonymousChatSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Sidebar from '../components/dashboard/SideBar';
import TopBar from '../components/dashboard/TopBar';
import ProfileTab from '../components/dashboard/ProfileTab';
import DiscoverTab from '../components/dashboard/DiscoverTab';
import MessagesTab from '../components/dashboard/MessagesTab';
import SettingsTab from '../components/dashboard/SettingsTab';
import AnonymTab from '../components/dashboard/AnonymTab';
import MatchAnimation from '../components/common/MatchAnimation';
import NewMatchesModal from '../components/common/NewMatchModal';
import { getSocket, initializeSocket } from '../utils/socketManager';
import api from '../utils/api';

const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.profile);
  const { userId } = useSelector((state) => state.auth);
  const { newMatches, showNewMatchesModal, currentMatchIndex } = useSelector((state) => state.user);
  const anonymousChatStatus = useSelector((state) => state.anonymousChat.status);

  const [isMatch, setIsMatch] = useState(false);
  const [matchedUser, setMatchedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [chatSessionId, setChatSessionId] = useState(null);

  useEffect(() => {
    if (userId) {
      initializeSocket(userId);
      dispatch(fetchNewMatches(userId)).then((action) => {
        if (fetchNewMatches.fulfilled.match(action)) {
          if (action.payload.length > 0) {
            dispatch(setNewMatches(action.payload));
          }
        }
      });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (!location.pathname.includes('/dashboard/messages')) {
      dispatch(clearAllMessageIndicators(userId));
    }
  }, [location, dispatch, userId]);

  useEffect(() => {
    const socket = getSocket();
    if (socket) {
      socket.on('chat ended', () => {
        dispatch(resetChat());
      });

      socket.on('reset chat', () => {
        dispatch(resetChat());
        setMatchedUser(null);
        setChatSessionId(null);
      });

      return () => {
        socket.off('chat ended');
        socket.off('reset chat');
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname !== '/dashboard/anonym' && anonymousChatStatus === 'matched') {
      dispatch(setActiveChat({
        chatPartner: matchedUser,
        chatSessionId: chatSessionId,
      }));
    }
  }, [location, anonymousChatStatus, matchedUser, chatSessionId, dispatch]);

  const handleNewMatch = useCallback(async (matchData) => {
    try {
      const matchUserId = matchData?.user_id || matchData.matchedUserId;
      if (!matchUserId) {
        console.error('Invalid match data received:', matchData);
        return;
      }
  
      const response = await api.get('/users/user', { params: { userId: matchUserId } });
      const fullProfileData = { ...response.data, seen: false };
      dispatch(addNewMatch(fullProfileData));
      setIsMatch(true);
      setMatchedUser(fullProfileData);
    } catch (error) {
      console.error('Error fetching full profile data:', error);
      const limitedMatchData = {
        user_id: matchData.user_id || matchData.matchedUserId,
        first_name: 'New Match',
        seen: false
      };
      dispatch(addNewMatch(limitedMatchData));
      setIsMatch(true);
      setMatchedUser(limitedMatchData);
    }
  }, [dispatch]);

  useEffect(() => {
    const socket = getSocket();
    if (socket) {
      socket.on('new match', handleNewMatch);
      socket.on('matched', (data) => {
        dispatch(setAnonymousChatStatus('matched'));
        setMatchedUser(data.matchedUser);
        setChatSessionId(data.chatSessionId);
      });
      return () => {
        socket.off('new match', handleNewMatch);
        socket.off('matched');
      };
    }
  }, [handleNewMatch, dispatch]);

  const handleCloseNewMatchesModal = useCallback(async () => {
    if (!user || newMatches.length === 0) {
      dispatch(closeNewMatchesModal());
      return;
    }
  
    const matchToClose = newMatches[currentMatchIndex];
    
    try {
      await dispatch(clearNewMatch(matchToClose.user_id)).unwrap();
      if (newMatches.every(match => match.seen)) {
        dispatch(closeNewMatchesModal());
      } else {
        dispatch(incrementMatchIndex());
      }
    } catch (error) {
      console.error('Error clearing new match:', error);
    }
  }, [dispatch, user, newMatches, currentMatchIndex]);

  const handleAnimationComplete = useCallback(() => {
    setIsMatch(false);
    setMatchedUser(null);
  }, []);

  const handleStatusChange = useCallback((status) => {
    dispatch(setAnonymousChatStatus(status));
  }, [dispatch]);

  const handleMatchedUserChange = useCallback((user) => {
    setMatchedUser(user);
  }, []);

  const handleChatSessionIdChange = useCallback((id) => {
    setChatSessionId(id);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/users/user', { params: { userId } });
        if (response.headers['content-type'].includes('application/json')) {
          dispatch(setUserProfile(response.data));
        } else {
          throw new Error('Received non-JSON response from server');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        dispatch(setUserProfile(null));
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchUserData();
    } else {
      setIsLoading(false);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    return () => {
      dispatch(closeNewMatchesModal());
    };
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
      // Handle logout error (e.g., show an error message)
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center bg-gradient-to-br from-indigo-700 to-indigo-600">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="flex h-screen items-center justify-center bg-gradient-to-br from-indigo-700 to-indigo-600 text-white text-xl">
        Error: Unable to load user data. Please try refreshing the page.
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gradient-to-br from-indigo-700 to-indigo-600">
      <Sidebar onLogout={handleLogout} />
      <main className="flex-1 flex flex-col overflow-hidden pt-20 md:pt-0">
        <TopBar onLogout={handleLogout} />
        <div className="flex-1 overflow-y-auto p-4">
          <AnimatePresence mode="wait">
            <motion.div
              key={location.pathname}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="h-full"
            >
              <Routes>
                <Route path="discover" element={<DiscoverTab user={user} />} />
                <Route path="messages" element={<MessagesTab user={user} />} />
                <Route path="profile" element={<ProfileTab user={user} />} />
                <Route path="settings" element={<SettingsTab user={user} onLogout={handleLogout} />} />
                <Route 
                  path="anonym" 
                  element={
                    <AnonymTab 
                      user={user} 
                      anonymousChatStatus={anonymousChatStatus}
                      onStatusChange={handleStatusChange}
                      onMatchedUserChange={handleMatchedUserChange}
                      onChatSessionIdChange={handleChatSessionIdChange}
                    />
                  } 
                />
                <Route path="*" element={<Navigate to="/dashboard/discover" replace />} />
              </Routes>
            </motion.div>
          </AnimatePresence>
        </div>
      </main>
      {showNewMatchesModal && newMatches.length > 0 && (
        <NewMatchesModal
          match={newMatches[currentMatchIndex]}
          onClose={handleCloseNewMatchesModal}
          totalMatches={newMatches.length}
          currentMatchIndex={currentMatchIndex}
        />
      )}
      <MatchAnimation 
        isMatch={isMatch}
        matchedUser={matchedUser}
        onAnimationComplete={handleAnimationComplete}
      />
    </div>
  );
};

export default Dashboard;