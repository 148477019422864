import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const LoadingSpinner = () => (
    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  );
  
  const PhoneVerification = ({ onVerificationComplete }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [error, setError] = useState('');
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [cooldown, setCooldown] = useState(0);

    useEffect(() => {
        const verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        });
        setRecaptchaVerifier(verifier);

        return () => {
            if (verifier) {
                verifier.clear();
            }
        };
    }, []);

    useEffect(() => {
        let interval;
        if (cooldown > 0) {
            interval = setInterval(() => {
                setCooldown((prevCooldown) => prevCooldown - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [cooldown]);

    const formatCooldown = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleSendVerification = async () => {
        if (!recaptchaVerifier || isSendingCode) {
            return;
        }

        setIsSendingCode(true);
        setError('');

        try {
            const formattedPhoneNumber = '+' + phoneNumber;
            const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, recaptchaVerifier);
            setConfirmationResult(confirmationResult);
            setVerificationId(confirmationResult.verificationId);
            setCooldown(180); // Set cooldown to 3 minutes (180 seconds)
        } catch (error) {
            console.error('Error sending verification:', error);
            setError('Failed to send verification code. Please try again.');
        } finally {
            setIsSendingCode(false);
        }
    };

    const handleConfirmVerification = async () => {
        if (!confirmationResult || isVerifying) {
            return;
        }

        setIsVerifying(true);
        setError('');

        try {
            const credential = await confirmationResult.confirm(verificationCode);
            const user = credential.user;
            onVerificationComplete(user.uid);
        } catch (error) {
            console.error('Error confirming verification:', error);
            setError('Invalid verification code. Please try again.');
        } finally {
            setIsVerifying(false);
        }
    };

    return (
        <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
            <div id="recaptcha-container"></div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {!verificationId ? (
                <div className="space-y-4">
                    <div className="phone-input-container">
                        <PhoneInput
                            country={'tr'}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            inputProps={{
                                name: 'phone',
                                required: true,
                                className: 'w-full pl-[40px] pr-2 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500'
                            }}
                            containerClass="w-full"
                            dropdownClass="bg-white border border-gray-300 rounded-md"
                            buttonClass="rounded-l-md !border-r-0"
                        />
                    </div>
                    <button 
                        onClick={handleSendVerification}
                        disabled={isSendingCode || cooldown > 0}
                        className={`w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out flex items-center justify-center ${(isSendingCode || cooldown > 0) ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {isSendingCode && <LoadingSpinner />}
                        {cooldown > 0 ? `Resend in ${formatCooldown(cooldown)}` : 'Send Verification Code'}
                    </button>
                </div>
            ) : (
                <div className="space-y-4">
                    <input
                        type="text"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        placeholder="Enter verification code"
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    <button 
                        onClick={handleConfirmVerification}
                        disabled={isVerifying}
                        className={`w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out flex items-center justify-center ${isVerifying ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {isVerifying && <LoadingSpinner />}
                        Confirm Verification
                    </button>
                </div>
            )}
        </div>
    );
};

export default PhoneVerification;