import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { getLocationString } from '../utils/locationUtils';
import { MapPin, Search } from 'lucide-react'; // Assuming you're using Lucide icons

const LocationSelector = ({ initialLocation, onLocationSelect, customStyles }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(initialLocation);
  const [currentLocationName, setCurrentLocationName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialLocation && initialLocation.coordinates) {
      setSelectedLocation(initialLocation);
      fetchLocationName(initialLocation.coordinates[1], initialLocation.coordinates[0]);
    }
  }, [initialLocation]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
        setIsFocused(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const fetchLocationName = async (lat, lon) => {
    try {
      const locString = await getLocationString({ coordinates: [lon, lat] });
      setCurrentLocationName(locString);
      setSearchQuery(locString);
    } catch (error) {
      console.error('Error fetching location name:', error);
    }
  };

  const fetchSuggestions = async (query) => {
    if (query.length < 2) {
      setSuggestions([]);
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: query,
          format: 'json',
          limit: 10,
          addressdetails: 1,
          'accept-language': 'en'
        }
      });
      
      const processedSuggestions = response.data
        .map(item => {
          const address = item.address;
          let locationName = '';
          let details = '';

          if (address.city) {
            locationName = address.city;
            details = address.state ? `${address.state}, ${address.country}` : address.country;
          } else if (address.town) {
            locationName = address.town;
            details = address.state ? `${address.state}, ${address.country}` : address.country;
          } else if (address.village) {
            locationName = address.village;
            details = address.state ? `${address.state}, ${address.country}` : address.country;
          } else if (address.county) {
            locationName = address.county;
            details = address.state ? `${address.state}, ${address.country}` : address.country;
          } else if (address.state) {
            locationName = address.state;
            details = address.country;
          } else if (address.country) {
            locationName = address.country;
          }

          return {
            ...item,
            displayName: locationName,
            details: details,
            fullName: `${locationName}${details ? ', ' + details : ''}`
          };
        })
        .filter(item => item.displayName && item.displayName.trim() !== '')
        .filter((item, index, self) =>
          index === self.findIndex((t) => t.fullName === item.fullName)
        )
        .sort((a, b) => {
          if (a.displayName === b.displayName) {
            return a.details.length - b.details.length;
          }
          return 0;
        })
        .slice(0, 5);

      setSuggestions(processedSuggestions);
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 300),
    []
  );

  useEffect(() => {
    if (isFocused && searchQuery && searchQuery.length >= 2) {
      debouncedFetchSuggestions(searchQuery);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [searchQuery, debouncedFetchSuggestions, isFocused]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setSelectedLocation(null);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
    if (searchQuery.length >= 2) {
      setShowSuggestions(true);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const location = {
      type: 'Point',
      coordinates: [parseFloat(suggestion.lon), parseFloat(suggestion.lat)],
      displayName: suggestion.fullName
    };
    setSelectedLocation(location);
    setSearchQuery(suggestion.fullName);
    onLocationSelect(location); // Call the function passed as a prop
    setCurrentLocationName(suggestion.fullName);
    setSuggestions([]);
    setShowSuggestions(false);
    setIsFocused(false);
    onLocationSelect(location);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const defaultStyles = {
    input: "w-full p-3 bg-white bg-opacity-20 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-white placeholder-gray-300",
    suggestionList: "absolute z-50 mt-1 w-full bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm max-h-60",
    suggestion: "cursor-pointer select-none relative py-2 px-3 text-white hover:bg-white hover:bg-opacity-20"
  };

  const styles = {
    input: customStyles?.input || defaultStyles.input,
    suggestionList: customStyles?.suggestionList || defaultStyles.suggestionList,
    suggestion: customStyles?.suggestion || defaultStyles.suggestion
  };

  return (
    <div className="relative" ref={wrapperRef}>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          name="location"
          id="location"
          className={`${styles.input} pl-10`}
          placeholder="Start typing a city"
          value={searchQuery}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        {isLoading && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
            <svg className="animate-spin h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <ul className={styles.suggestionList}>
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.place_id}
              onClick={() => handleSuggestionClick(suggestion)}
              className={styles.suggestion}
            >
              <div className="flex items-center">
                <MapPin size={16} className="mr-2" />
                <div>
                  <span className="font-medium">{suggestion.displayName}</span>
                  <span className="text-sm text-gray-300 block">{suggestion.details}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationSelector;