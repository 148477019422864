import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSkeleton from './LoadingSkeleton';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { getSocket } from '../../utils/socketManager';
import { setNewMessageIndicator, clearNewMessageIndicator } from '../../redux/slices/userSlice';
import api from '../../utils/api';

const ChatBox = ({ user, match, messages: initialMessages }) => {
  const { t } = useTranslation();
  const [chatMessages, setChatMessages] = useState(initialMessages || []);
  const [newMessage, setNewMessage] = useState('');
  const [isUnmatched, setIsUnmatched] = useState(false);
  const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();

  const fetchMessages = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(`/messages/messages`, {
        params: { 
          userId: user.user_id,
          correspondingUserId: match.user_id
        }
      });
      setChatMessages(response.data);
      setIsUnmatched(false);
    } catch (error) {
      console.error(t('chat.errorFetchingMessages'), error);
      if (error.response && error.response.status === 404) {
        setIsUnmatched(true);
      }
    } finally {
      setLoading(false);
    }
  }, [user.user_id, match.user_id, t]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  useEffect(() => {
    const socket = getSocket();
    if (socket) {
      const handleNewMessage = (message) => {
        if (message.from_userId === match.user_id || message.to_userId === match.user_id) {
          setChatMessages(prevMessages => {
            const messageExists = prevMessages.some(msg => 
              msg.timestamp === message.timestamp && 
              msg.from_userId === message.from_userId &&
              msg.to_userId === message.to_userId
            );
            if (!messageExists) {
              return [...prevMessages, message];
            }
            return prevMessages;
          });
          dispatch(clearNewMessageIndicator({ userId: user.user_id, fromUserId: match.user_id }));
        } else {
          dispatch(setNewMessageIndicator({ userId: user.user_id, fromUserId: message.from_userId }));
        }
      };

      socket.on('new messages tab message', handleNewMessage);

      return () => {
        socket.off('new messages tab message', handleNewMessage);
      };
    }
  }, [user.user_id, match.user_id, dispatch]);

  useEffect(() => {
    dispatch(clearNewMessageIndicator({ userId: user.user_id, fromUserId: match.user_id }));
  }, [dispatch, user.user_id, match.user_id]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  const debouncedSendMessage = useCallback(
    debounce((message) => {
      const socket = getSocket();
      if (socket) {
        socket.emit('messages tab chat', message);
      }
    }, 150),
    []
  );

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || isUnmatched) return;

    const message = {
      from_userId: user.user_id,
      to_userId: match.user_id,
      message: newMessage,
      timestamp: new Date().toISOString()
    };

    try {
      setChatMessages(prevMessages => [...prevMessages, message]);
      setNewMessage('');
      debouncedSendMessage(message);
    } catch (error) {
      console.error(t('chat.errorSendingMessage'), error);
      if (error.response && error.response.status === 404) {
        setIsUnmatched(true);
      }
    }
  };

  if (isUnmatched) {
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <p className="text-gray-500">{t('chat.noLongerMatched')}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4 pb-0">
        {loading ? (
          <div className="space-y-4">
            {[...Array(5)].map((_, index) => (
              <LoadingSkeleton key={index} />
            ))}
          </div>
        ) : (
          chatMessages.map((msg, index) => (
            <div
              key={`${msg.from_userId}-${msg.to_userId}-${msg.timestamp}-${index}`}
              className={`mb-2 ${msg.from_userId === user.user_id ? 'text-right' : 'text-left'}`}
            >
              <div
                className={`inline-block p-2 rounded-lg ${
                  msg.from_userId === user.user_id ? 'bg-blue-500 text-white' : 'bg-gray-200'
                }`}
              >
                {msg.message}
              </div>
            </div>
          ))
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="p-2 bg-white border-t">
        <form onSubmit={sendMessage} className="flex">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="flex-1 border rounded-l-lg p-2 text-sm"
            placeholder={t('chat.typeMessage')}
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 rounded-r-lg text-sm"
          >
            {t('chat.send')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatBox;