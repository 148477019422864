import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Heart, MessageCircle, User, Settings, UserPlus } from 'lucide-react';
import logo from '../../images/DestyMe-logo.svg'

const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const newMessageIndicators = useSelector(state => state.user.newMessageIndicators);
  const isInMessagesTab = location.pathname.includes('/dashboard/messages');

  const tabs = [
    { id: 'discover', icon: Heart, label: t('dashboard.discover'), color: 'from-purple-600 to-pink-500' },
    { id: 'messages', icon: MessageCircle, label: t('dashboard.messages'), color: 'from-blue-500 to-teal-400',
      hasNewIndicator: newMessageIndicators.length > 0 && !isInMessagesTab
    },
    { id: 'anonym', icon: UserPlus, label: t('dashboard.anonym'), color: 'from-yellow-500 to-orange-500' },
    { id: 'profile', icon: User, label: t('dashboard.profile'), color: 'from-orange-500 to-red-500' },
    { id: 'settings', icon: Settings, label: t('dashboard.settings'), color: 'from-green-500 to-emerald-400' },
  ];

  return (
    <div className="w-full md:w-32 h-auto md:h-full flex flex-row md:flex-col bg-indigo-900 bg-opacity-100 md:bg-opacity-20 md:backdrop-filter md:backdrop-blur-lg fixed bottom-0 md:relative z-50">
      <div className="hidden md:flex h-20 items-center justify-center">
        <img src={logo} alt={t('general.logoAlt')} className="w-24 h-24" />
      </div>
      {tabs.map((tab) => (
        <Link
          key={tab.id}
          to={`/dashboard/${tab.id}`}
          className={`flex-1 md:w-full aspect-square flex flex-col items-center justify-center max-h-[84px] md:max-h-full text-white transition-all relative ${
            location.pathname.includes(tab.id) ? 'bg-indigo-600 md:bg-white md:bg-opacity-30' : 'hover:bg-indigo-700 md:hover:bg-white md:hover:bg-opacity-10'
          }`}
        >
          <tab.icon size={24} />
          <span className="text-xs mt-1 hidden md:inline">{tab.label}</span>
          {tab.hasNewIndicator && (
            <div className="absolute top-2 right-2 w-3 h-3 bg-green-500 rounded-full"></div>
          )}
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;