import axios from 'axios';

const cache = new Map();

export const getLocationString = async (location) => {
  if (typeof location === 'string') {
    return location;
  }

  if (location && location.coordinates) {
    const [lon, lat] = location.coordinates;
    const cacheKey = `${lat},${lon}`;

    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    }

    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse`, {
        params: {
          format: 'json',
          lat,
          lon,
        }
        // Remove the headers property entirely
      });

      // Extract city and state/country
      const address = response.data.address;
      let locationString = address.city || address.town || address.village || '';
      if (address.state) {
        locationString += locationString ? `, ${address.state}` : address.state;
      } else if (address.country) {
        locationString += locationString ? `, ${address.country}` : address.country;
      }

      cache.set(cacheKey, locationString);
      return locationString;
    } catch (error) {
      console.error('Error fetching location name:', error);
      return 'Location not available';
    }
  }

  return 'Location not set';
};