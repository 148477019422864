import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoUrl from '../../images/matchmates-logo-no-text.svg'

const MatchAnimation = React.memo(({ isMatch, matchedUser, onAnimationComplete }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const logoRef = useRef(null);

  useEffect(() => {
    console.log('MatchAnimation: isMatch changed:', isMatch);
    if (isMatch) {
      console.log('MatchAnimation: Setting isVisible to true');
      setIsVisible(true);
      const timeoutId = setTimeout(() => {
        console.log('MatchAnimation: Animation timeout reached');
        setIsVisible(false);
        onAnimationComplete();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [isMatch, onAnimationComplete]);

  useEffect(() => {
    console.log('MatchAnimation: isVisible changed:', isVisible);
    if (isVisible && canvasRef.current && containerRef.current) {
      console.log('MatchAnimation: Starting animation');
      const canvas = canvasRef.current;
      const container = containerRef.current;
      const ctx = canvas.getContext('2d');
      let animationFrameId;
      const particles = [];

      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;

      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      for (let i = 0; i < 50; i++) {
        const angle = Math.random() * Math.PI * 2;
        const radius = Math.random() * Math.min(canvas.width, canvas.height) * 0.4;
        particles.push({
          x: centerX + Math.cos(angle) * radius,
          y: centerY + Math.sin(angle) * radius,
          radius: Math.random() * 2 + 1,
          speedX: (Math.random() - 0.5) * 3,
          speedY: (Math.random() - 0.5) * 3
        });
      }

      let opacity = 1;
      const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        particles.forEach(particle => {
          ctx.beginPath();
          ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
          ctx.fillStyle = `rgba(255, 255, 255, ${opacity * 0.8})`;
          ctx.fill();
          particle.x += particle.speedX;
          particle.y += particle.speedY;
          if (particle.x < 0 || particle.x > canvas.width) particle.speedX *= -1;
          if (particle.y < 0 || particle.y > canvas.height) particle.speedY *= -1;
        });

        if (opacity > 0) {
          opacity -= 0.01;
        }

        if (logoRef.current) {
          logoRef.current.style.opacity = opacity;
        }

        animationFrameId = requestAnimationFrame(animate);
      };

      animate();

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [isVisible]);

  const calculateLogoSize = () => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      return Math.min(width, height) * 0.4;
    }
    return 160; // Default size
  };

  console.log('MatchAnimation: Rendering, isVisible:', isVisible);
  if (!isVisible) return null;

  return (
    <div ref={containerRef} className="absolute inset-0 flex items-center justify-center overflow-hidden">
      <div className="relative w-full h-full">
      <div className="absolute inset-0 bg-blue-500 bg-opacity-70 flex flex-col items-center justify-center animate-fadeIn z-10">
          <div className="text-white text-4xl font-bold mb-4">{t('matches.itsAMatch')}</div>
          {matchedUser && (
            <div className="text-white text-xl">
              {t('matches.matchedWith', { name: matchedUser.first_name })}
            </div>
          )}
        </div>
        <canvas 
          ref={canvasRef} 
          className="absolute inset-0 pointer-events-none z-20"
        />
        <img 
          ref={logoRef}
          src={logoUrl} 
          alt="Logo" 
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain z-30"
          style={{ width: `${calculateLogoSize()}px`, height: `${calculateLogoSize()}px` }}
        />
      </div>
    </div>
  );
});

export default MatchAnimation;