import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchPreferences = createAsyncThunk(
  'preferences/fetchPreferences',
  async (userId) => {
    const response = await axios.get(`http://${window.location.hostname}:3001/preferences`, {
      params: { userId }
    });
    return response.data;
  }
);

export const updatePreferences = createAsyncThunk(
  'preferences/updatePreferences',
  async ({ userId, preferences }) => {
    const response = await axios.put(`http://${window.location.hostname}:3001/preferences`, {
      userId,
      preferences
    });
    return response.data;
  }
);

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: {
    preferences: null,
    settings: null,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreferences.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPreferences.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.preferences = action.payload;
      })
      .addCase(fetchPreferences.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updatePreferences.fulfilled, (state, action) => {
        state.preferences = action.payload;
      });
  }
});

export default preferencesSlice.reducer;