import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserLocation, fetchFullProfile } from '../../redux/slices/userSlice';

export const useLocation = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.userId);
  const reduxLocation = useSelector(state => state.user.location);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      if (userId) {
        try {
          // First, try to get the location from the Redux store
          if (reduxLocation) {
            setLocation(reduxLocation);
            return;
          }

          // If not in Redux, try to get it from localStorage
          const savedLocation = localStorage.getItem('userLocation');
          if (savedLocation) {
            const parsedLocation = JSON.parse(savedLocation);
            dispatch(setUserLocation(parsedLocation));
            setLocation(parsedLocation);
            return;
          }

          // If still no location, fetch the full profile
          const response = await dispatch(fetchFullProfile(userId)).unwrap();
          if (response.location) {
            dispatch(setUserLocation(response.location));
            setLocation(response.location);
            return;
          }

          // As a last resort, try to get the current location
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const currentLocation = {
                type: 'Point',
                coordinates: [position.coords.longitude, position.coords.latitude]
              };
              await dispatch(setUserLocation(currentLocation));
              setLocation(currentLocation);
            },
            (error) => console.error('Error getting location:', error)
          );
        } catch (error) {
          console.error('Error fetching location:', error);
        }
      }
    };

    fetchLocation();
  }, [dispatch, userId, reduxLocation]);

  return location;
};