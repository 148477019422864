import React, { useState } from 'react';
import { X, ChevronLeft, ChevronRight, Info, Camera, MapPin, Briefcase, GraduationCap, Heart } from 'lucide-react';

const ProfilePreviewModal = ({ user, onClose, isOpen }) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [showInfo, setShowInfo] = useState(true);

  if (!user || !isOpen) return null;

  const nextMedia = () => {
    setCurrentMediaIndex((prevIndex) => 
      prevIndex === user.media.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevMedia = () => {
    setCurrentMediaIndex((prevIndex) => 
      prevIndex === 0 ? user.media.length - 1 : prevIndex - 1
    );
  };

  const age = user.dob_year ? new Date().getFullYear() - parseInt(user.dob_year) : '';

  const ToggleIcon = showInfo ? Camera : Info;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative w-full max-w-sm h-[80vh] overflow-hidden rounded-lg bg-white">
        {showInfo ? (
          <div className="w-full h-full p-6 overflow-y-auto text-black">
            <h3 className="text-3xl font-bold mb-2">
              {user.first_name}, {age}
            </h3>
            <div className="flex items-center mb-4">
              <MapPin size={20} className="mr-2" />
              <p>{user.distance ? `${user.distance} km away` : 'Distance unknown'}</p>
            </div>
            {user.occupation && (
              <div className="flex items-center mb-2">
                <Briefcase size={20} className="mr-2" />
                <p>{user.occupation}</p>
              </div>
            )}
            {user.education && (
              <div className="flex items-center mb-4">
                <GraduationCap size={20} className="mr-2" />
                <p>{user.education}</p>
              </div>
            )}
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">About Me</h4>
              <p>{user.about || "No bio available"}</p>
            </div>
            {user.interests && user.interests.length > 0 && (
              <div>
                <h4 className="text-xl font-semibold mb-2">Interests</h4>
                <div className="flex flex-wrap gap-2">
                  {user.interests.map((interest, index) => (
                    <span key={index} className="bg-gray-200 px-3 py-1 rounded-full text-sm flex items-center">
                      <Heart size={14} className="mr-1" />
                      {interest}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {user.media && user.media.length > 0 ? (
              <>
                {user.media[currentMediaIndex].type === 'image' ? (
                  <img
                    src={user.media[currentMediaIndex].url}
                    alt={user.first_name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <video
                    src={user.media[currentMediaIndex].url}
                    controls
                    className="w-full h-full object-cover"
                  />
                )}
                {user.media.length > 1 && (
                  <>
                    <button
                      onClick={prevMedia}
                      className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-1 hover:bg-gray-200 transition-colors"
                    >
                      <ChevronLeft size={24} />
                    </button>
                    <button
                      onClick={nextMedia}
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-1 hover:bg-gray-200 transition-colors"
                    >
                      <ChevronRight size={24} />
                    </button>
                  </>
                )}
                <div className="absolute top-2 left-1/2 transform -translate-x-1/2 bg-white text-black px-2 py-1 rounded-full text-sm">
                  {currentMediaIndex + 1} / {user.media.length}
                </div>
              </>
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-gray-200">
                <p className="text-gray-500">No media available</p>
              </div>
            )}
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
              <h2 className="text-white text-2xl font-bold">
                {user.first_name}, {age}
              </h2>
              {user.occupation && <p className="text-white text-sm">{user.occupation}</p>}
              {user.distance && <p className="text-white text-sm">{user.distance} km away</p>}
            </div>
          </>
        )}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-white text-black rounded-full p-1 hover:bg-gray-200 transition-colors"
        >
          <X size={24} />
        </button>
        <button
          onClick={() => setShowInfo(!showInfo)}
          className="absolute top-2 left-2 bg-white text-black rounded-full p-1 hover:bg-gray-200 transition-colors"
        >
          <ToggleIcon size={24} />
        </button>
      </div>
    </div>
  );
};

export default ProfilePreviewModal;