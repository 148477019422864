import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../utils/api';

const EmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await api.get(`/auth/verify-email/${token}`);
        setVerificationStatus('Email verified successfully. Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000);
      } catch (error) {
        setVerificationStatus('Email verification failed. Please try again or contact support.');
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        <h2 className="text-2xl font-bold mb-4">Email Verification</h2>
        <p className="text-gray-600">{verificationStatus}</p>
      </div>
    </div>
  );
};

export default EmailVerification;