// socketManager.js
import { io } from 'socket.io-client';

let socket = null;

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const initializeSocket = (userId) => {
  if (!socket || socket.disconnected) {
    const token = localStorage.getItem('authToken');
    socket = io(SOCKET_URL, {
      path: '/api/socket.io',
      transports: ['websocket'],
      upgrade: false,
      auth: { token },
      query: { userId }
    });

    socket.on('connect', () => {
      console.log('Socket connected');
      socket.emit('authenticate', userId);
      socket.emit('join chat', userId);
      socket.emit('check_status', userId);
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    socket.io.on("reconnect", () => {
      console.log('Socket reconnected');
      socket.emit('authenticate', userId);
      socket.emit('join chat', userId);
      socket.emit('check_status', userId);
    });
  }
  return socket;
};

export const getSocket = () => socket;