import React from 'react';
import AnonymousChat from '../../components/AnonymousChat';

const AnonymTab = ({ user, anonymousChatStatus, onStatusChange, onMatchedUserChange, onChatSessionIdChange }) => {
  return (
    <div className=''>
      <AnonymousChat 
        user={user}
        anonymousChatStatus={anonymousChatStatus}
        onStatusChange={onStatusChange}
        onMatchedUserChange={onMatchedUserChange}
        onChatSessionIdChange={onChatSessionIdChange}
      />
    </div>
  );
};

export default AnonymTab;