import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setAuth } from '../redux/slices/authSlice';
import { setOnboardingCompleted } from '../redux/slices/userSlice';
import { X, Eye, EyeOff, Loader } from 'lucide-react';
import PhoneVerification from './auth/PhoneVerification';
import api from '../utils/api';
import DOMPurify from 'dompurify';

const AuthModal = ({ setShowModal, isSignUp }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [tempUserData, setTempUserData] = useState(null);
    const [showPhoneVerification] = useState(false);
    const [showEmailVerification, setShowEmailVerification] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // New states for resend functionality
    const [resendTimer, setResendTimer] = useState(90);
    const [canResend, setCanResend] = useState(false);
    const [isResending, setIsResending] = useState(false);

    // Forgot password states
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
    const [resetStage, setResetStage] = useState('initial');
    const [resetToken, setResetToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [timeRemaining, setTimeRemaining] = useState(90);
    const [timerActive, setTimerActive] = useState(false);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        let timer;
        if (timerActive && timeRemaining > 0) {
            timer = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeRemaining === 0) {
            setResetStage('initial');
            setError(t('auth.tokenExpired'));
            setForgotPasswordMode(false);
        }
        return () => clearInterval(timer);
    }, [timerActive, timeRemaining, t]);

    // New useEffect for resend timer
    useEffect(() => {
        let timer;
        if (showEmailVerification && resendTimer > 0) {
            timer = setInterval(() => {
                setResendTimer((prevTime) => prevTime - 1);
            }, 1000);
        } else if (resendTimer === 0) {
            setCanResend(true);
        }
        return () => clearInterval(timer);
    }, [showEmailVerification, resendTimer]);

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePassword = (password) => {
        return password.length >= 6;
    };

    const sanitizeInput = (input) => {
        return DOMPurify.sanitize(input);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = sanitizeInput(value);
        if (name === 'email') {
            setEmail(sanitizedValue);
        } else if (name === 'password') {
            setPassword(sanitizedValue);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(sanitizedValue);
        } else if (name === 'resetToken') {
            setResetToken(sanitizedValue);
        } else if (name === 'newPassword') {
            setNewPassword(sanitizedValue);
        } else if (name === 'confirmNewPassword') {
            setConfirmNewPassword(sanitizedValue);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (forgotPasswordMode) {
            handleForgotPassword();
            return;
        }

        const sanitizedEmail = sanitizeInput(email);
        const sanitizedPassword = sanitizeInput(password);

        if (isSignUp) {
            if (!validateEmail(sanitizedEmail)) {
                setError(t('auth.invalidEmail'));
                return;
            }

            if (!validatePassword(sanitizedPassword)) {
                setError(t('auth.passwordLength'));
                return;
            }

            const sanitizedConfirmPassword = sanitizeInput(confirmPassword);
            if (sanitizedPassword !== sanitizedConfirmPassword) {
                setError(t('auth.passwordsMismatch'));
                return;
            }

            try {
                await sendVerificationEmail(sanitizedEmail);
                setTempUserData({ email: sanitizedEmail, password: sanitizedPassword });
                setShowEmailVerification(true);
                setSuccessMessage(t('auth.verifyEmail'));
                setResendTimer(90);
                setCanResend(false);
            } catch (error) {
                setError(t('auth.verificationFailed'));
            }
        } else {
            try {
                const response = await api.post('/auth/login', { email: sanitizedEmail, password: sanitizedPassword });
                handleAuthSuccess(response.data);
            } catch (error) {
                setError(t('auth.authError'));
            }
        }
    };

    const sendVerificationEmail = async (email) => {
        await api.post('/auth/send-verification-email', { email });
    };

    const handleResendVerification = async () => {
        if (canResend && !isResending) {
            setIsResending(true);
            try {
                await sendVerificationEmail(tempUserData.email);
                setSuccessMessage(t('auth.verificationResent'));
                setResendTimer(90);
                setCanResend(false);
            } catch (error) {
                setError(t('auth.resendFailed'));
            } finally {
                setIsResending(false);
            }
        }
    };

    const handleForgotPassword = async () => {
        setIsLoading(true);
        setError(null);
        setSuccessMessage(null);

        const sanitizedEmail = sanitizeInput(email);
        const sanitizedResetToken = sanitizeInput(resetToken);
        const sanitizedNewPassword = sanitizeInput(newPassword);
        const sanitizedConfirmNewPassword = sanitizeInput(confirmNewPassword);

        if (resetStage === 'initial') {
            try {
                await api.initiatePasswordReset(sanitizedEmail);
                setResetStage('emailSent');
                setSuccessMessage(t('auth.resetEmailSent'));
                setTimeRemaining(90);
                setTimerActive(true);
            } catch (error) {
                setError(error.response?.data?.error || t('auth.resetInitiateError'));
            }
        } else if (resetStage === 'emailSent') {
            try {
                await api.verifyResetToken(sanitizedEmail, sanitizedResetToken);
                setResetStage('tokenVerified');
                setSuccessMessage(t('auth.tokenVerified'));
                setTimerActive(false);
            } catch (error) {
                setError(error.response?.data?.error || t('auth.tokenVerificationError'));
            }
        } else if (resetStage === 'tokenVerified') {
            if (sanitizedNewPassword !== sanitizedConfirmNewPassword) {
                setError(t('auth.passwordMismatch'));
                setIsLoading(false);
                return;
            }

            if (!validatePassword(sanitizedNewPassword)) {
                setError(t('auth.passwordLength'));
                setIsLoading(false);
                return;
            }

            try {
                await api.resetPassword(sanitizedEmail, sanitizedResetToken, sanitizedNewPassword);
                setSuccessMessage(t('auth.passwordResetSuccess'));
                setForgotPasswordMode(false);
                setResetStage('initial');
            } catch (error) {
                setError(error.response?.data?.error || t('auth.passwordResetError'));
            }
        }

        setIsLoading(false);
    };

    const handleEmailVerificationSubmit = async (e) => {
        e.preventDefault();
        const sanitizedVerificationCode = sanitizeInput(verificationCode);
        try {
            await api.post('/auth/verify-email', {
                email: tempUserData.email,
                code: sanitizedVerificationCode
            });

            const signupResponse = await api.post('/auth/signup', tempUserData);
            handleAuthSuccess(signupResponse.data);
        } catch (error) {
            setError(t('auth.verificationFailed'));
        }
    };

    const handleAuthSuccess = (data) => {
        const { token, userId, onboardingCompleted } = data;
        localStorage.setItem('authToken', token);
        dispatch(setAuth({ authToken: token, userId }));
        dispatch(setOnboardingCompleted(onboardingCompleted));
    
        if (isSignUp || !onboardingCompleted) {
            navigate('/onboarding');
        } else {
            navigate('/dashboard');
        }
    };

    const handleVerificationComplete = async (firebaseUid) => {
        try {
            const response = await api.post('/auth/signup', {
                ...tempUserData,
                firebaseUid
            });
            handleAuthSuccess(response.data);
        } catch (error) {
            setError(t('auth.signupError'));
        }
    };

    const renderForgotPasswordForm = () => {
        switch (resetStage) {
            case 'initial':
                return (
                    <>
                        <input
                            type="email"
                            value={email}
                            onChange={handleInputChange}
                            name="email"
                            placeholder={t('auth.emailAddress')}
                            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-gray-700"
                            required
                        />
                        <button
                            type="submit"
                            className="w-full bg-purple-600 text-white p-3 rounded-lg hover:bg-purple-700 transition duration-300 flex items-center justify-center"
                            disabled={isLoading}
                        >
                            {isLoading ? <Loader className="animate-spin mr-2" size={20} /> : null}
                            {t('auth.sendResetEmail')}
                        </button>
                    </>
                );
            case 'emailSent':
                return (
                    <>
                        <input
                            type="text"
                            value={resetToken}
                            onChange={handleInputChange}
                            name="resetToken"
                            placeholder={t('auth.resetToken')}
                            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-gray-700"
                            required
                        />
                        <p className="text-sm text-gray-600 mb-4">
                            {t('auth.timeRemaining', { seconds: timeRemaining })}
                        </p>
                        <button
                            type="submit"
                            className="w-full bg-purple-600 text-white p-3 rounded-lg hover:bg-purple-700 transition duration-300 flex items-center justify-center"
                            disabled={isLoading}
                        >
                            {isLoading ? <Loader className="animate-spin mr-2" size={20} /> : null}
                            {t('auth.verifyToken')}
                        </button>
                    </>
                );
            case 'tokenVerified':
                return (
                    <>
                        <div className="relative mb-4">
                            <input
                                type={showPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={handleInputChange}
                                name="newPassword"
                                placeholder={t('auth.newPassword')}
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-gray-700"
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute right-3 top-3 text-gray-500"
                            >
                                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                            </button>
                        </div>
                        <div className="relative mb-4">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmNewPassword}
                                onChange={handleInputChange}
                                name="confirmNewPassword"
                                placeholder={t('auth.confirmNewPassword')}
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-gray-700"
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="absolute right-3 top-3 text-gray-500"
                            >
                                {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                            </button>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-purple-600 text-white p-3 rounded-lg hover:bg-purple-700 transition duration-300 "
                        >
                            {t('auth.resetPassword')}
                        </button>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-gradient-to-br from-blue-500 via-purple-600 to-pink-500 rounded-lg p-1 max-w-md w-full">
                <div className="bg-white rounded-lg p-8">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-3xl font-bold text-purple-600">
                            {forgotPasswordMode 
                                ? t('auth.forgotPassword') 
                                : (isSignUp ? t('auth.signUp') : t('auth.logIn'))}
                        </h2>
                        <button onClick={() => setShowModal(false)} className="text-gray-500 hover:text-gray-700 transition duration-300">
                            <X size={24} />
                        </button>
                    </div>
                    {showEmailVerification ? (
                        <div>
                            <p className="text-gray-700 mb-4">{successMessage}</p>
                            <form onSubmit={handleEmailVerificationSubmit}>
                                <input
                                    type="text"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(sanitizeInput(e.target.value))}
                                    placeholder={t('auth.enterCode')}
                                    maxLength="6"
                                    pattern="\d{6}"
                                    className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-gray-700 bg-white"
                                    required
                                />
                                <button
                                    type="submit"
                                    className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white p-3 rounded-lg hover:opacity-90 transition duration-300"
                                >
                                    {t('auth.verifyEmail')}
                                </button>
                            </form>
                            <button
    onClick={handleResendVerification}
    disabled={!canResend || isResending}
    className={`w-full mt-4 p-3 rounded-lg transition duration-300 ${
        canResend && !isResending
            ? 'bg-gradient-to-r from-pink-500 to-purple-600 text-white hover:opacity-90'
            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
    }`}
>
    {isResending ? (
        <div className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {t('auth.sending')}
        </div>
    ) : canResend ? (
        t('auth.resendCode')
    ) : (
        t('auth.resendCodeIn', { seconds: resendTimer })
    )}
</button>
                        </div>
                    ) : showPhoneVerification ? (
                        <PhoneVerification 
                            onVerificationComplete={handleVerificationComplete} 
                        />
                    ) : (
                        <>
                            {isSignUp && !forgotPasswordMode && (
                                <p className="mb-6 text-sm text-gray-600">
                                    {t('auth.termsAndConditions')}
                                </p>
                            )}
                            <form onSubmit={handleSubmit}>
                                {forgotPasswordMode ? (
                                    renderForgotPasswordForm()
                                ) : (
                                    <>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder={t('auth.email')}
                                            required={true}
                                            value={email}
                                            onChange={handleInputChange}
                                            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-gray-700"
                                        />
                                        <div className="relative mb-4">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                name="password"
                                                placeholder={t('auth.password')}
                                                required={true}
                                                value={password}
                                                onChange={handleInputChange}
                                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-gray-700"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => setShowPassword(!showPassword)}
                                                className="absolute right-3 top-3 text-gray-500"
                                            >
                                                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                            </button>
                                        </div>
                                        {isSignUp && (
                                            <div className="relative mb-4">
                                                <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    id="password-check"
                                                    name="confirmPassword"
                                                    placeholder={t('auth.confirmPassword')}
                                                    required={true}
                                                    value={confirmPassword}
                                                    onChange={handleInputChange}
                                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-gray-700"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    className="absolute right-3 top-3 text-gray-500"
                                                >
                                                    {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                                </button>
                                            </div>
                                        )}
                                        <button
                                            type="submit"
                                            className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white p-3 rounded-lg hover:opacity-90 transition duration-300"
                                        >
                                            {isSignUp ? t('auth.signUp') : t('auth.logIn')}
                                        </button>
                                    </>
                                )}
                            </form>
                            {!isSignUp && !forgotPasswordMode && (
                                <button
                                    onClick={() => setForgotPasswordMode(true)}
                                    className="mt-4 text-sm text-purple-600 hover:text-purple-800 transition duration-300"
                                >
                                    {t('auth.forgotPassword')}
                                </button>
                            )}
                            {forgotPasswordMode && (
                                <button
                                    onClick={() => {
                                        setForgotPasswordMode(false);
                                        setResetStage('initial');
                                        setError(null);
                                        setSuccessMessage(null);
                                    }}
                                    className="mt-4 text-sm text-purple-600 hover:text-purple-800 transition duration-300"
                                >
                                    {t('auth.backToLogin')}
                                </button>
                            )}
                            {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
                            {successMessage && <p className="text-green-500 mt-4 text-center">{successMessage}</p>}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
    };
    
    export default AuthModal;