import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogOut } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../../redux/slices/userSlice';
import logo from '../../images/DestyMe-logo.svg';

const TopBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <>
      {/* Mobile version */}
      <div className="md:hidden h-16 bg-indigo-800 flex items-center justify-between px-4 fixed top-0 left-0 right-0 z-10">
        <button
          onClick={handleLogout}
          className="text-white flex items-center"
        >
          <LogOut size={20} className="mr-2" />
          <span className="text-sm">{t('settings.logout')}</span>
        </button>
        <div className="flex items-center"> 
          <img src={logo} alt="MatchMates Logo" className="w-20 h-20 mr-2" /> 
        </div>
      </div>

      {/* Desktop version */}
      <div className="hidden md:flex h-20 bg-indigo-900 bg-opacity-20 backdrop-filter backdrop-blur-lg items-center px-6">
        {/* You can add desktop-specific content here if needed */}
      </div>
    </>
  );
};

export default TopBar;