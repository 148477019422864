import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { X, Check, Info, MapPin, Briefcase, GraduationCap, ChevronLeft, ChevronRight, Loader, Ban, Clock, Send, Globe, UserPlus, Flag } from 'lucide-react';
import { swipeUser, addMatch, updateMatches, blockUser } from '../redux/slices/userSlice';
import { setStatus, setCountry, setActiveChat, resetChat } from '../redux/slices/anonymousChatSlice';
import Modal from './dashboard/DiscoverModal';
import api from '../utils/api';
import { useTranslation } from 'react-i18next';
import { getSocket, initializeSocket } from '../utils/socketManager';
import DOMPurify from 'dompurify';

const AnonymousChat = ({ user, onStatusChange, onMatchedUserChange, onChatSessionIdChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status, country, activeChat } = useSelector(state => state.anonymousChat);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [matchedUser, setMatchedUser] = useState(null);
  const [userCounts, setUserCounts] = useState({ global: 0, turkey: 0 });
  const [error, setError] = useState(null);
  const [partnerLeft, setPartnerLeft] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300);
  const [showFeedbackScreen, setShowFeedbackScreen] = useState(false);
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [chatPartnerProfile, setChatPartnerProfile] = useState(null);
  const [showProfileCard, setShowProfileCard] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [chatSessionId, setChatSessionId] = useState(null);
  const [waitingForPartnerFeedback, setWaitingForPartnerFeedback] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [feedbackCountdown, setFeedbackCountdown] = useState(15);
  const [reconnectingTimer, setReconnectingTimer] = useState(false);
  const [isInActiveChat, setIsInActiveChat] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);

  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  const activeRoomRef = useRef(null);
  const messagesEndRef = useRef(null);
  const user_id = user.user_id;

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (activeChat) {
      onStatusChange('matched');
      setMatchedUser(activeChat.chatPartner);
      setChatSessionId(activeChat.chatSessionId);
      setIsInActiveChat(true);
    }
  }, [activeChat, onStatusChange]);

  const setupSocketListeners = useCallback((socket) => {
    const eventHandlers = {
      'mutual like': handleMutualLike,
      'matched': handleMatched,
      'chat message': handleChatMessage,
      'user count update': handleUserCountUpdate,
      'user left': handleUserLeft,
      'feedback result': handleFeedbackResult,
      'waiting for partner': handleWaitingForPartner,
      'partner disconnected': handlePartnerDisconnected,
      'restore_chat': handleRestoreChat,
      'restore_messages': handleRestoreMessages,
      'reconnect': handleReconnect,
      'disconnect': handleDisconnect,
      'reset chat': handleResetChat,
      'restore_status': handleRestoreStatus,
      'match_timeout': handleMatchTimeout
    };

    Object.entries(eventHandlers).forEach(([event, handler]) => {
      socket.off(event, handler);
      socket.on(event, handler);
    });

    return () => {
      Object.entries(eventHandlers).forEach(([event, handler]) => {
        socket.off(event, handler);
      });
    };
  }, []);

  useEffect(() => {
    const socket = initializeSocket(user_id);
    if (socket) {
      socket.emit('authenticate', user_id);
      socket.emit('join chat', user_id);
      const cleanup = setupSocketListeners(socket);
      
      return () => {
        cleanup();
        socket.off('authenticate');
        socket.off('join chat');
      };
    }
  }, [user_id, setupSocketListeners]);

  useEffect(() => {
    const savedStatus = localStorage.getItem('chatStatus');
    if (savedStatus) {
      dispatch(setStatus(savedStatus));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem('chatStatus', status);
  }, [status]);

  const resetState = useCallback(() => {
    dispatch(resetChat());
    setMatchedUser(null);
    setMessages([]);
    setPartnerLeft(false);
    setTimeLeft(300);
    setError(null);
    setShowFeedbackScreen(false);
    setFeedbackGiven(false);
    setChatSessionId(null);
    setWaitingForPartnerFeedback(false); 
    setChatPartnerProfile(null);
    setShowProfileCard(false);
    setIsInActiveChat(false);
    onStatusChange('disconnected');
    onMatchedUserChange(null);
    onChatSessionIdChange(null);
    
    const socket = getSocket();
    if (socket) {
      socket.emit('cancel blind date', { user_id, country });
    }
  }, [dispatch, onStatusChange, onMatchedUserChange, onChatSessionIdChange, user_id, country]);

  useEffect(() => {
    const socket = getSocket();
    if (socket) {
      const handleFeedbackResult = (data) => {
        if (data.mutualLike) {
          // Handle mutual like
        } else if (data.shouldReset) {
          resetState();
        }
      };

      socket.on('feedback result', handleFeedbackResult);

      return () => {
        socket.off('feedback result', handleFeedbackResult);
      };
    }
  }, [resetState]);

  useEffect(() => {
    const socket = getSocket();
    if (socket) {
      const handleChatEnded = ({ shouldShowFeedback }) => {
        if (shouldShowFeedback) {
          onStatusChange('feedback');
          setShowFeedbackScreen(true);
        } else {
          dispatch(resetChat());
          resetState();
        }
        setIsInActiveChat(false);
      };
  
      socket.on('chat ended', handleChatEnded);
  
      return () => {
        socket.off('chat ended', handleChatEnded);
      };
    }
  }, [dispatch, resetState, onStatusChange]);

  useEffect(() => {
    let timer;
    if (status === 'matched' && !reconnectingTimer) {
      if (timeLeft > 0) {
        timer = setInterval(() => {
          setTimeLeft((prevTime) => {
            if (prevTime <= 1) {
              clearInterval(timer);
              handleEndChatClick();
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      } else {
        handleEndChatClick();
      }
    } else if (reconnectingTimer) {
      setReconnectingTimer(false);
    }
    return () => clearInterval(timer);
  }, [status, timeLeft, reconnectingTimer]);

  useEffect(() => {
    if (waitingForPartnerFeedback) {
      setFeedbackCountdown(15);
      const interval = setInterval(() => {
        setFeedbackCountdown(prevCount => {
          if (prevCount <= 1) {
            clearInterval(interval);
            resetState();
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }
  }, [waitingForPartnerFeedback, resetState]);

  const handleStartBlindDate = () => {
    dispatch(setStatus('waiting'));
    const socket = getSocket();
    if (socket) {
      socket.emit('start blind date', { user_id, country });
    }
  };

  const handleInputChange = (e) => {
    const sanitizedInput = sanitizeInput(e.target.value);
    setInputMessage(sanitizedInput.slice(0, 500));
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '' && !partnerLeft) {
      const socket = getSocket();
      if (socket) {
        const sanitizedMessage = sanitizeInput(inputMessage.trim());
        if (sanitizedMessage.length > 0) {
          const messageData = { 
            message: sanitizedMessage,
            from: user_id,
            to: matchedUser,
            chatSessionId: chatSessionId 
          };
          socket.emit('chat message', messageData);
          setInputMessage('');
          scrollToBottom();
        }
      } else {
        setError('Unable to send message. Please try reconnecting.');
      }
    }
  };

  const handleFeedback = (liked) => {
    const socket = getSocket();
    if (socket) {
      socket.emit('submit feedback', {
        userId: user_id,
        chatPartnerUserId: matchedUser,
        liked,
        chatSessionId
      });
      setFeedbackGiven(true);
      setShowFeedbackScreen(false);    
      if (liked) {
        setWaitingForPartnerFeedback(true);
      } else {
        resetState();
      }
    } else {
      setError('Unable to submit feedback. Please try reconnecting.');
    }
  };

  const handleSwipe = useCallback(async (direction) => {
    try {
      const response = await dispatch(swipeUser({ 
        userId: user.user_id, 
        swipedUserId: chatPartnerProfile.user_id, 
        direction 
      }));
      
      if (response.payload.isMatch) {
        dispatch(addMatch(chatPartnerProfile));
        const matchesResponse = await api.get('/matches/matches', {
          params: { userId: user.user_id }
        });
        dispatch(updateMatches(matchesResponse.data));
      }
  
      resetState();
    } catch (error) {
      setError('An error occurred while processing your swipe. Please try again.');
      resetState();
    }
  }, [chatPartnerProfile, dispatch, resetState, user.user_id]);

  const handleBlockUser = async () => {
    try {
      await dispatch(blockUser({ userId: user_id, blockUserId: matchedUser }));
      
      const socket = getSocket();
      if (socket) {
        socket.emit('end chat', { 
          userId: user_id, 
          chatPartnerUserId: matchedUser,
          chatSessionId,
          shouldShowFeedback: false
        });
      }
  
      resetState();
      setShowBlockModal(false);
      setError('User blocked successfully. The chat has ended.');
  
    } catch (error) {
      setError('Failed to block user. Please try again.');
    }
  };

  const handleEndChatClick = useCallback(() => {
    const socket = getSocket();
    if (socket) {
      socket.emit('end chat', { 
        userId: user_id, 
        chatPartnerUserId: matchedUser,
        chatSessionId
      });
    }
  }, [user_id, matchedUser, chatSessionId]);

  const handleMutualLike = useCallback((data) => {
    if (data.chatPartnerProfile) {
      setChatPartnerProfile(data.chatPartnerProfile);
      setShowProfileCard(true);
      setWaitingForPartnerFeedback(false);
    } else {
      setError('An error occurred while processing the mutual like.');
    }
  }, []);

  const handleMatched = useCallback((data) => {
    onStatusChange('matched');
    setMatchedUser(data.matchedUser);
    onMatchedUserChange(data.matchedUser);
    setPartnerLeft(false);
    setTimeLeft(300);
    setChatSessionId(data.chatSessionId);
    onChatSessionIdChange(data.chatSessionId);
    setIsInActiveChat(true);
  }, [onStatusChange, onMatchedUserChange, onChatSessionIdChange]);

  const handleChatMessage = useCallback((data) => {
    setMessages(prevMessages => [
      ...prevMessages,
      {
        text: data.message,
        sender: data.from === user_id ? 'You' : data.from === 'System' ? 'System' : 'Anonym'
      }
    ]);
  }, [user_id]);

  const handleUserCountUpdate = useCallback((data) => {
    setUserCounts(prevCounts => ({
      ...prevCounts,
      [data.country]: data.count
    }));
  }, []);

  const handleUserLeft = useCallback(() => {
    setPartnerLeft(true);
    setMessages(prevMessages => [
      ...prevMessages,
      {
        text: 'Your chat partner has left the room.',
        sender: 'System'
      }
    ]);
  }, []);

  const handleFeedbackResult = useCallback((data) => {
    if (data.mutualLike) {
      // This case is handled by the 'mutual like' event
    } else {
      if (data.partnerDisconnected) {
        setError('Your chat partner disconnected. Please try another match.');
      }
      resetState();
    }
  }, [resetState]);

  const handleWaitingForPartner = useCallback(() => {
    setWaitingForPartnerFeedback(true);
  }, []);

  const handlePartnerDisconnected = useCallback(() => {
    setError('Your chat partner disconnected unexpectedly.');
    resetState();
  }, [resetState]);

  const handleReconnect = useCallback(() => {
    setIsReconnecting(false);
  }, []);

  const handleDisconnect = useCallback(() => {
    setIsReconnecting(true);
  }, []);

  const handleRestoreChat = useCallback((data) => {
    onStatusChange('matched');
    setMatchedUser(data.chatPartner);
    setChatSessionId(data.chatSessionId);
    activeRoomRef.current = data.roomName;
    setTimeLeft(data.remainingTime);
    setReconnectingTimer(true);
    setIsInActiveChat(true);
  }, [onStatusChange]);

  const handleRestoreMessages = useCallback((messages) => {
    setMessages(messages.map(msg => ({
      text: msg.message,
      sender: msg.from === user.user_id ? 'You' : msg.from === 'System' ? 'System' : 'Anonym'
    })));
  }, [user.user_id]);

  const handleResetChat = useCallback(() => {
    dispatch(resetChat());
    resetState();
  }, [dispatch, resetState]);

  const handleRestoreStatus = useCallback((restoredStatus) => {
    dispatch(setStatus(restoredStatus));
  }, [dispatch]);

  const handleMatchTimeout = useCallback(() => {
    dispatch(setStatus('disconnected'));
    setError('Match timeout. Please try again.');
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      const socket = getSocket();
      if (socket && status === 'waiting') {
        socket.emit('cancel blind date', { user_id, country });
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [user_id, country, status]);

  // In AnonymousChat.js

  useEffect(() => {
    const socket = getSocket();
    if (socket) {
      socket.emit('check_status', user_id);
  
      socket.on('restore_status', (status) => {
        if (status === 'waiting') {
          dispatch(setStatus('waiting'));
        } else if (status === 'disconnected') {
          resetState();
        }
      });
  
      return () => {
        socket.off('restore_status');
      };
    }
  }, [user_id, dispatch, resetState]);

  const ProfileCard = ({ user }) => (
    <div className="w-full max-w-md aspect-[3/4] bg-white rounded-3xl shadow-xl overflow-hidden relative transition-transform duration-300 ease-in-out transform">
      <div className="absolute top-0 left-0 w-full h-full">
        {user.media && user.media.length > 0 ? (
          <>
            {user.media[currentMediaIndex].type === 'image' ? (
              <img 
                src={user.media[currentMediaIndex].url} 
                alt={user.first_name} 
                className="w-full h-full object-cover"
              />
            ) : (
              <video 
                src={user.media[currentMediaIndex].url} 
                className="w-full h-full object-cover"
                controls
              />
            )}
            {user.media.length > 1 && (
              <>
                <button 
                  onClick={() => setCurrentMediaIndex(prev => prev === 0 ? user.media.length - 1 : prev - 1)} 
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 transition-opacity duration-300 hover:bg-opacity-75"
                >
                  <ChevronLeft size={24} />
                </button>
                <button 
                  onClick={() => setCurrentMediaIndex(prev => prev === user.media.length - 1 ? 0 : prev + 1)} 
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 transition-opacity duration-300 hover:bg-opacity-75"
                >
                  <ChevronRight size={24} />
                </button>
                <div className="absolute bottom-24 left-1/2 transform -translate-x-1/2 flex space-x-2">
                  {user.media.map((_, index) => (
                    <div 
                      key={index} 
                      className={`w-2 h-2 rounded-full transition-all duration-300 ${index === currentMediaIndex ? 'bg-white scale-125' : 'bg-gray-400'}`}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <Loader size={48} className="text-gray-400" />
          </div>
        )}
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black via-black to-transparent p-6">
          <h3 className="text-white text-3xl font-bold mb-2">
            {user.first_name}, {new Date().getFullYear() - user.dob_year}
          </h3>
          <div className="flex items-center text-white mb-2">
            <MapPin size={16} className="mr-2" />
            <p className="truncate">
              {user.distance ? `${user.distance} km away` : 'Distance unknown'}
            </p>
          </div>
          {user.occupation && (
            <div className="flex items-center text-white mb-2">
              <Briefcase size={16} className="mr-2" />
              <p className="truncate">{user.occupation}</p>
            </div>
          )}
          {user.education && (
            <div className="flex items-center text-white mb-2">
              <GraduationCap size={16} className="mr-2" />
              <p className="truncate">{user.education}</p>
            </div>
          )}
          <p className="text-white mt-2 line-clamp-2">{user.about || "No bio available"}</p>
        </div>
      </div>
      <div className="absolute bottom-6 left-0 right-0 flex justify-between px-8">
        <button 
          className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-lg transition-transform duration-300 hover:scale-110"
          onClick={() => handleSwipe('left')}
        >
          <X className="text-red-500" size={32} />
        </button>
        <button 
          className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-lg transition-transform duration-300 hover:scale-110"
          onClick={() => handleSwipe('right')}
        >
          <Check className="text-green-500" size={32} />
        </button>
      </div>
      <button
        className="absolute top-4 right-4 bg-white bg-opacity-50 rounded-full p-2 transition-opacity duration-300 hover:bg-opacity-75"
        onClick={() => setIsModalOpen(true)}
      >
        <Info className="text-gray-800" size={24} />
      </button>
    </div>
  );

  return (
    <div className="flex flex-col h-screen bg-gray-50 text-gray-800">
      {/* Header */}
      <div className="bg-white border-b border-gray-200 p-4 flex flex-wrap justify-between items-center">
        <div className="flex items-center space-x-4 mb-2 sm:mb-0">
          <h1 className="text-xl font-semibold text-gray-800">{t('anonymousChat.welcome')}</h1>
          <div className="relative">
            <select
              value={country}
              onChange={(e) => dispatch(setCountry(e.target.value))}
              className="appearance-none bg-gray-100 border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="global">{t('general.global')}</option>
              <option value="turkey">{t('general.turkey')}</option>
            </select>
            <Globe className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" size={16} />
          </div>
        </div>
        <div className="text-sm text-gray-600 flex items-center">
          <UserPlus size={16} className="mr-2" />
          {t('anonymousChat.availableUsers', { count: userCounts[country] })}
        </div>
      </div>
  
      {/* Main Content */}
      <div className="flex-grow flex flex-col overflow-hidden">
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 m-4 rounded-md">
            {error}
          </div>
        )}
  
        {status === 'disconnected' && (
          <div className="flex-grow flex flex-col items-center justify-center p-4 text-center">
            <h2 className="text-3xl font-semibold mb-4 text-gray-800">{t('anonymousChat.welcome')}</h2>
            <p className="mb-8 text-gray-600">{t('anonymousChat.startPrompt')}</p>
            <button 
              onClick={handleStartBlindDate}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-md transition duration-300 shadow-sm flex items-center"
            >
              <UserPlus size={20} className="mr-2" />
              {t('anonymousChat.startBlindDate')}
            </button>
          </div>
        )}
  
        {status === 'waiting' && (
          <div className="flex-grow flex flex-col items-center justify-center p-4 text-center">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">{t('anonymousChat.findingMatch')}</h2>
            <div className="inline-block animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 mb-4"></div>
            <p className="mb-8 text-gray-600">{t('anonymousChat.waitingMessage')}</p>
            <button 
              onClick={resetState}
              className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 shadow-sm flex items-center"
            >
              <X size={20} className="mr-2" />
              {t('general.cancel')}
            </button>
          </div>
        )}
  
        {status === 'matched' && (
          <div className="flex-grow flex flex-col overflow-hidden">
            <div className="bg-white border border-gray-200 p-4 rounded-md shadow-sm flex justify-between items-center">
              <div className="flex items-center space-x-3">
                <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center text-white font-semibold">
                  {chatPartnerProfile?.first_name?.[0] || '?'}
                </div>
                <div>
                  <h2 className="font-semibold text-gray-800">{t('anonymousChat.anonymousUser')}</h2>
                  <div className="flex items-center text-sm text-gray-500">
                    <Clock size={14} className="mr-1" />
                    <p>{t('anonymousChat.timeLeft', { minutes: Math.floor(timeLeft / 60), seconds: (timeLeft % 60).toString().padStart(2, '0') })}</p>
                  </div>
                </div>
              </div>
              <button 
                onClick={() => setShowBlockModal(true)}
                className="text-red-500 hover:text-red-600 transition duration-300"
              >
                <Flag size={20} />
              </button>
            </div>
  
            <div className="flex-grow overflow-y-auto p-4 space-y-4">
              {messages.map((msg, index) => (
                <div key={index} className={`flex ${msg.sender === 'You' ? 'justify-end' : 'justify-start'}`}>
                  <div className={`max-w-[70%] rounded-md px-4 py-2 break-words ${
                    msg.sender === 'You' ? 'bg-blue-100 text-blue-800' : 
                    msg.sender === 'System' ? 'bg-yellow-100 text-yellow-800' : 'bg-gray-100 text-gray-800'
                  }`}>
                    {msg.sender !== 'System' && (
                      <div className={`font-bold mb-1 text-sm ${
                        msg.sender === 'You' ? 'text-right' : 'text-left'
                      }`}>
                        {t(`anonymousChat.${msg.sender.toLowerCase()}`)}
                      </div>
                    )}
                    <div>{msg.text}</div>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
  
            {partnerLeft && (
              <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-800 p-4 m-4 rounded-md">
                <p className="font-semibold">{t('anonymousChat.partnerLeft')}</p>
                <p>{t('anonymousChat.endOrWait')}</p>
              </div>
            )}
  
            <div className="bg-white border-t border-gray-200 p-4 pb-20 md:pb-4">
              <div className="flex flex-col sm:flex-row mb-4">
                <input
                  type="text"
                  value={inputMessage}
                  onChange={handleInputChange}
                  placeholder={t('anonymousChat.typeMessage')}
                  className="flex-grow mb-2 sm:mb-0 sm:mr-2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSendMessage();
                    }
                  }}
                  disabled={partnerLeft}
                  maxLength={500}
                />
                <button 
                  onClick={handleSendMessage}
                  className={`w-full sm:w-auto bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 flex items-center justify-center ${partnerLeft ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={partnerLeft}
                >
                  <Send size={20} className="mr-2" />
                  {t('anonymousChat.send')}
                </button>
              </div>
              <button 
                onClick={handleEndChatClick}
                className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 shadow-sm flex items-center justify-center"
              >
                <X size={20} className="mr-2" />
                {t('anonymousChat.endChat')}
              </button>
            </div>
          </div>
        )}
      </div>
  
      {/* Modals */}
      {showFeedbackScreen && !feedbackGiven && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-8 max-w-md w-full shadow-lg">
            <h3 className="text-2xl font-semibold mb-6 text-center text-gray-800">{t('anonymousChat.feedback.title')}</h3>
            <p className="text-gray-600 mb-6 text-center">
              {t('anonymousChat.feedback.description')}
            </p>
            <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-4">
              <button
                onClick={() => handleFeedback(true)}
                className="w-full sm:w-auto bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-6 rounded-md transition duration-300 flex items-center justify-center"
              >
                <Check className="mr-2" size={20} /> {t('anonymousChat.feedback.great')}
              </button>
              <button
                onClick={() => handleFeedback(false)}
                className="w-full sm:w-auto bg-red-500 hover:bg-red-600 text-white font-semibold py-3 px-6 rounded-md transition duration-300 flex items-center justify-center"
              >
                <X className="mr-2" size={20} /> {t('anonymousChat.feedback.notForMe')}
              </button>
            </div>
          </div>
        </div>
      )}
  
      {waitingForPartnerFeedback && status !== 'disconnected' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-lg">
            <h3 className="text-xl font-semibold mb-4 text-center text-gray-800">{t('anonymousChat.waitingForPartner')}</h3>
            <p className="text-center mb-4 text-gray-600">{t('anonymousChat.timeRemaining', { seconds: feedbackCountdown })}</p>
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-blue-500"></div>
            </div>
          </div>
        </div>
      )}
  
      {showProfileCard && chatPartnerProfile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <ProfileCard user={chatPartnerProfile} />
        </div>
      )}
  
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {chatPartnerProfile ? (
          <div className="text-center">
            <h3 className="text-2xl font-bold mb-4">
              {chatPartnerProfile.first_name}, {new Date().getFullYear() - chatPartnerProfile.dob_year}
            </h3>
            <img 
              src={chatPartnerProfile.media[0]?.url || '/api/placeholder/400/600'} 
              alt={chatPartnerProfile.first_name} 
              className="w-full h-64 object-cover rounded-lg mb-4" 
            />
            <p className="text-gray-700 mb-2">
              {chatPartnerProfile.distance ? t('profile.distance', { distance: chatPartnerProfile.distance }) : t('profile.distanceUnknown')}
            </p>
            <p className="text-gray-700 mb-2">
              <strong>{t('profile.about')}:</strong> {chatPartnerProfile.about || t('profile.noBio')}
            </p>
            <p className="text-gray-700 mb-2">
              <strong>{t('profile.gender')}:</strong> {chatPartnerProfile.gender_identity}
            </p>
            <p className="text-gray-700 mb-2">
              <strong>{t('profile.interestedIn')}:</strong> {chatPartnerProfile.gender_interest}
            </p>
            {chatPartnerProfile.occupation && (
              <p className="text-gray-700 mb-2">
                <strong>{t('profile.occupation')}:</strong> {chatPartnerProfile.occupation}
              </p>
            )}
            {chatPartnerProfile.education && (
              <p className="text-gray-700 mb-2">
                <strong>{t('profile.education')}:</strong> {chatPartnerProfile.education}
              </p>
            )}
            <div className="flex justify-between mt-6">
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg flex items-center transition-colors duration-300"
                onClick={() => {
                  setIsModalOpen(false);
                  handleSwipe('left');
                }}
              >
                <X size={20} className="mr-2" /> {t('matches.pass')}
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg flex items-center transition-colors duration-300"
                onClick={() => {
                  setIsModalOpen(false);
                  handleSwipe('right');
                }}
              >
                {t('matches.like')} <Check size={20} className="ml-2" />
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p>{t('general.loading')}</p>
          </div>
        )}
      </Modal>
  
      {showBlockModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full shadow-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">{t('chat.block')}</h3>
            <p className="mb-6 text-gray-600">{t('chat.blockWarning')}</p>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
              <button 
                onClick={() => setShowBlockModal(false)}
                className="w-full sm:w-auto px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300"
              >
                {t('general.cancel')}
              </button>
              <button 
                onClick={handleBlockUser}
                className="w-full sm:w-auto px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
              >
                {t('chat.block')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnonymousChat;