// index.js
import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store,persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import setupAxiosInterceptors from './utils/axiosConfig';


import App from './App';
import 'leaflet/dist/leaflet.css';

setupAxiosInterceptors();


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

    <App />
    </PersistGate>

  </Provider>
);
setupAxiosInterceptors();

