import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dashboard from './pages/Dashboard';
import HomePage from './pages/HomePage';
import OnBoarding from './pages/OnBoarding';
import useAuthToken from './components/hooks/useAuthToken';
import ProtectedRoute from './components/auth/ProtectedRoute';
import EmailVerification from './components/auth/EmailVerification';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import TermsOfService from './pages/TermsOfService'; 
import PrivacyPolicy from './pages/PrivacyPolicy';
import LoadingSpinner from './components/LoadingSpinner'; 
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  const loading = useAuthToken();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const onboardingCompleted = useSelector(state => state.user.onboardingCompleted);

  if (loading) {
    return <LoadingSpinner />; 
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
      <ScrollToTop /> 
        <Routes>
          <Route 
            path="/" 
            element={
              isAuthenticated ? (
                onboardingCompleted ? (
                  <Navigate to="/dashboard/discover" replace />
                ) : (
                  <Navigate to="/onboarding" replace />
                )
              ) : (
                <HomePage />
              )
            } 
          />
          <Route 
            path="/dashboard/*" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} onboardingCompleted={onboardingCompleted}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route 
            path="/onboarding" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} onboardingRequired={false}>
                <OnBoarding />
              </ProtectedRoute>
            }
          />
          <Route path="/verify-email/:token" element={<EmailVerification />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />


        </Routes>
      </Router>
    </I18nextProvider>
  );
};

export default App;