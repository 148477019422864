import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { fetchFullProfile } from '../slices/userSlice'; // Import this from your userSlice

export const loginUser = createAsyncThunk(
  'auth/login',
  async (credentials, { dispatch }) => {
    const response = await api.post('/auth/login', credentials);
    const { token, userId } = response.data;
    
    // Set auth state
    dispatch(setAuth({ authToken: token, userId }));
    
    // Fetch full profile including location
    await dispatch(fetchFullProfile(userId));
    
    return response.data;
  }
);

const initialState = {
  authToken: localStorage.getItem('authToken') || null,
  userId: localStorage.getItem('userId') || null,
  isAuthenticated: !!localStorage.getItem('authToken'),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.authToken = action.payload.authToken;
      state.userId = action.payload.userId;
      state.isAuthenticated = true;
      localStorage.setItem('authToken', action.payload.authToken);
      localStorage.setItem('userId', action.payload.userId);
    },
    clearAuth: (state) => {
      state.authToken = null;
      state.userId = null;
      state.isAuthenticated = false;
      localStorage.removeItem('authToken');
      localStorage.removeItem('userId');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.authToken = action.payload.token;
        state.userId = action.payload.userId;
        state.isAuthenticated = true;
      });
  },
});

export const { setAuth, clearAuth } = authSlice.actions;

export default authSlice.reducer;