import React, { useReducer, useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { X, Check, RefreshCw, Loader, MapPin, Info} from 'lucide-react';
import { swipeUser, addMatch, addNewMatch } from '../../redux/slices/userSlice';
import { useLocation } from '../hooks/useLocation';
import ProfilePreviewModal from './ProfilePreviewModal';
import api from '../../utils/api';

// Action types
const FETCH_USERS_START = 'FETCH_USERS_START';
const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
const SET_END_REACHED = 'SET_END_REACHED';
const REMOVE_USER = 'REMOVE_USER';
const SET_TRANSITIONING = 'SET_TRANSITIONING';
const RESET_STATE = 'RESET_STATE';

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_USERS_START:
      return { ...state, loading: true, error: null };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: [...state.users, ...action.payload],
        loading: false,
        currentPage: state.currentPage + 1,
        isEndReached: action.payload.length === 0
      };
    case FETCH_USERS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SET_END_REACHED:
      return { ...state, isEndReached: true, loading: false };
    case REMOVE_USER:
      return { ...state, users: state.users.slice(1) };
    case SET_TRANSITIONING:
      return { ...state, isTransitioning: action.payload };
    case RESET_STATE:
      return { ...initialState, currentPage: 1 };
    default:
      return state;
  }
};

const initialState = {
  users: [],
  loading: false,
  error: null,
  isEndReached: false,
  isTransitioning: false,
  currentPage: 1,
};

const DiscoverTab = React.memo(() => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const reduxDispatch = useDispatch();
  const user = useSelector(state => state.user.profile);
  const userId = useSelector(state => state.auth.userId);

  const isFetchingRef = useRef(false);
  const lastPreferenceUpdateRef = useRef(parseInt(localStorage.getItem('lastPreferenceUpdate') || '0'));
  const location = useLocation();
  const isOnline = useSelector(state => state.user.isOnline);

  const fetchUsers = useCallback(async (page = 1, limit = 10) => {
    if (isFetchingRef.current || state.isEndReached || !location) {
      return;
    }
    
    isFetchingRef.current = true;
    dispatch({ type: FETCH_USERS_START });
    
    try {
      if (!userId) {
        throw new Error('Authentication required');
      }
      
      const response = await api.get('/matches/users-to-swipe', {
        params: { 
          userId,
          lastPreferenceUpdate: lastPreferenceUpdateRef.current,
          page,
          limit,
          latitude: location.coordinates[1],
          longitude: location.coordinates[0]
        }
      });
      
      if (response && response.data && Array.isArray(response.data)) {
        dispatch({ type: FETCH_USERS_SUCCESS, payload: response.data });
        
        if (response.data.length === 0) {
          dispatch({ type: SET_END_REACHED });
        }
      } else {
        throw new Error('Invalid response format');
      }
      
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again later.';
      if (error.response && error.response.status === 401) {
        errorMessage = 'Authentication required. Please log in again.';
      } else if (error.response && error.response.status === 404) {
        errorMessage = 'No users found. Try adjusting your preferences.';
      }
      dispatch({ type: FETCH_USERS_FAILURE, payload: errorMessage });
    } finally {
      isFetchingRef.current = false;
    }
  }, [location, userId, dispatch, state.isEndReached]);

  useEffect(() => {
    if (location && !isFetchingRef.current && state.users.length === 0) {
      fetchUsers(1);
    }
  }, [fetchUsers, location, state.users.length]);

  useEffect(() => {
    if (state.users.length < 5 && !state.isEndReached && !state.loading && location && !isFetchingRef.current) {
      fetchUsers(state.currentPage);
    }
  }, [state.users.length, fetchUsers, state.isEndReached, state.currentPage, state.loading, location]);
  
  const removeCurrentUser = useCallback(() => {
    dispatch({ type: REMOVE_USER });
    
    if (state.users.length < 5 && !state.isEndReached) {
      fetchUsers(state.currentPage + 1);
    }
  }, [state.users.length, state.isEndReached, state.currentPage, fetchUsers]);

  const handleSwipe = useCallback(async (direction) => {
    if (state.users.length === 0) {
      dispatch({ type: FETCH_USERS_FAILURE, payload: 'No users available to swipe' });
      return;
    }
  
    dispatch({ type: SET_TRANSITIONING, payload: true });
  
    const currentUser = state.users[0];
  
    try {
      const response = await reduxDispatch(swipeUser({ 
        userId, 
        swipedUserId: currentUser.user_id, 
        direction 
      }));
  
      if (response.payload && response.payload.isMatch) {
        const matchedUser = response.payload.matchedUser || currentUser;
        reduxDispatch(addMatch(matchedUser));
        reduxDispatch(addNewMatch(matchedUser));
      }
      
      removeCurrentUser();
      
      if (state.users.length === 1) {
        dispatch({ type: SET_END_REACHED });
      }
  
    } catch (error) {
      dispatch({ type: FETCH_USERS_FAILURE, payload: 'Failed to process swipe. Please try again.' });
    } finally {
      dispatch({ type: SET_TRANSITIONING, payload: false });
    }
  }, [state.users, reduxDispatch, removeCurrentUser, userId]);

  const resetAndFetch = () => {
    dispatch({ type: RESET_STATE });
    fetchUsers(1);
  };

  if (state.loading && state.users.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader className="animate-spin text-white" size={48} />
      </div>
    );
  }

  if (state.error) {
    return (
      <div className="text-white text-2xl flex flex-col items-center justify-center h-full">
        <p className="mb-4">{state.error}</p>
        <button 
          onClick={resetAndFetch}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full flex items-center"
        >
          <RefreshCw size={20} className="mr-2" /> Try Again
        </button>
      </div>
    );
  }

  if (state.users.length === 0 && state.isEndReached) {
    return (
      <div className="text-white text-2xl flex flex-col items-center justify-center h-full">
        <p className="mb-4">No more users available at the moment.</p>
        <button 
          onClick={resetAndFetch}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full flex items-center"
        >
          <RefreshCw size={20} className="mr-2" /> Refresh
        </button>
      </div>
    );
  }

  const currentUser = state.users[0];

  if (!currentUser || state.isTransitioning) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader className="animate-spin text-white" size={48} />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full bg-gradient-to-br from-indigo-700 to-indigo-600 overflow-hidden">
      <div className="flex-1 overflow-y-auto flex items-center justify-center px-4 py-8 md:py-4">
        {state.loading && state.users.length === 0 ? (
          <div className="flex items-center justify-center">
            <Loader className="animate-spin text-white" size={48} />
          </div>
        ) : state.error ? (
          <div className="text-white text-xl flex flex-col items-center justify-center text-center">
            <p className="mb-4">{state.error}</p>
            <button 
              onClick={resetAndFetch}
              className="bg-white text-indigo-600 font-bold py-2 px-4 rounded-full flex items-center transition duration-300 hover:bg-opacity-90"
            >
              <RefreshCw size={20} className="mr-2" /> Try Again
            </button>
          </div>
        ) : state.users.length === 0 && state.isEndReached ? (
          <div className="text-white text-xl flex flex-col items-center justify-center text-center">
            <p className="mb-4">No more users available at the moment.</p>
            <button 
              onClick={resetAndFetch}
              className="bg-white text-indigo-600 font-bold py-2 px-4 rounded-full flex items-center mb-4 transition duration-300 hover:bg-opacity-90"
            >
              <RefreshCw size={20} className="mr-2" /> Refresh
            </button>
          </div>
        ) : (
          currentUser && !state.isTransitioning && (
            <div className="w-full max-w-sm mx-auto relative group">
              <div className="aspect-[3/4] bg-white rounded-3xl shadow-xl overflow-hidden">
                <img 
                  src={currentUser.media[0].url} 
                  alt={currentUser.first_name} 
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black via-black/60 to-transparent pt-24 pb-20 px-6">
                  <h3 className="text-white text-3xl font-bold mb-1">
                    {currentUser.first_name}, {new Date().getFullYear() - currentUser.dob_year}
                  </h3>
                  <div className="flex items-center text-white pb-5">
                    <MapPin size={16} className="mr-1" />
                    <p className="text-sm">
                      {currentUser.distance ? `${currentUser.distance} km away` : 'Distance unknown'}
                    </p>
                  </div>
                </div>
                <button
                  className="absolute top-4 right-4 bg-white/50 rounded-full p-2 transition-opacity duration-300 hover:bg-white/75"
                  onClick={() => setIsModalOpen(true)}
                >
                  <Info className="text-gray-800" size={24} />
                </button>
              </div>
              <div className="absolute inset-x-0 bottom-0 flex items-center justify-between px-6 pb-6">
                <div className="flex-1 flex items-center justify-start group">
                  <button 
                    aria-label="Dislike"
                    className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-lg transition-all duration-300 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 border-2 border-gray-300 group-hover:border-4 group-hover:border-red-500 group-hover:shadow-red-500/50 group-hover:shadow-xl"
                    onClick={() => handleSwipe('left')}
                  >
                    <X className="text-gray-500 group-hover:text-red-500 transition-colors duration-300" size={28} />
                  </button>
                </div>
                <div className="flex-1 flex items-center justify-end group">
                  <button 
                    aria-label="Like"
                    className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-lg transition-all duration-300 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border-2 border-gray-300 group-hover:border-4 group-hover:border-green-500 group-hover:shadow-green-500/50 group-hover:shadow-xl"
                    onClick={() => handleSwipe('right')}
                  >
                    <Check className="text-gray-500 group-hover:text-green-500 transition-colors duration-300" size={28} />
                  </button>
                </div>
              </div>
            </div>
          )
        )}
        {state.isTransitioning && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/50">
            <Loader className="animate-spin text-white" size={48} />
          </div>
        )}
      </div>
  
      <ProfilePreviewModal 
        user={currentUser} 
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </div>
  );
});

export default DiscoverTab;