import React, { useState, useRef,useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Activity, MessageCircle, User, Search, UserPlus, Zap, Shield, Globe, Star, Smartphone, Play, Pause} from 'lucide-react';
import { motion } from 'framer-motion';
import AuthModal from "../components/AuthModal";
import Nav from '../components/Nav';
import { Link } from 'react-router-dom';
import { logoutUser } from '../redux/slices/userSlice';
import demo from "../videos/demo.mp4"

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
  const authToken = useSelector((state) => state.auth.authToken);
  const { t } = useTranslation();
  const mainRef = useRef(null);
  const dispatch = useDispatch();

  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);

  const togglePlayPause = useCallback(() => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, []);

  const handleAuthClick = async (signUp) => {
    if (authToken) {
      try {
        await dispatch(logoutUser()).unwrap();
      } catch (error) {
      }
      return;
    }
    setShowModal(true);
    setIsSignUp(signUp);
  };

  const features = [
    { icon: UserPlus, titleKey: 'homePage.features.blindDating.title', descriptionKey: 'homePage.features.blindDating.description' },
    { icon: Shield, titleKey: 'homePage.features.safeExploration.title', descriptionKey: 'homePage.features.safeExploration.description' },
    { icon: Zap, titleKey: 'homePage.features.instantConnections.title', descriptionKey: 'homePage.features.instantConnections.description' },
    { icon: Globe, titleKey: 'homePage.features.locationBased.title', descriptionKey: 'homePage.features.locationBased.description' }
  ];

  const howItWorks = [
    { icon: UserPlus, titleKey: 'homePage.howItWorks.createProfile.title', descriptionKey: 'homePage.howItWorks.createProfile.description' },
    { icon: Zap, titleKey: 'homePage.howItWorks.setPreferences.title', descriptionKey: 'homePage.howItWorks.setPreferences.description' },
    { icon: Search, titleKey: 'homePage.howItWorks.discoverMatches.title', descriptionKey: 'homePage.howItWorks.discoverMatches.description' },
    { icon: MessageCircle, titleKey: 'homePage.howItWorks.connectAndChat.title', descriptionKey: 'homePage.howItWorks.connectAndChat.description' },
  ];

  const upcomingFeatures = [
    { icon: Activity, titleKey: 'homePage.upcomingFeatures.interestTags.title', descriptionKey: 'homePage.upcomingFeatures.interestTags.description' },
    { icon: User, titleKey: 'homePage.upcomingFeatures.groupChats.title', descriptionKey: 'homePage.upcomingFeatures.groupChats.description' },
    { icon: Smartphone, titleKey: 'homePage.upcomingFeatures.videoChat.title', descriptionKey: 'homePage.upcomingFeatures.videoChat.description' },
    { icon: Star, titleKey: 'homePage.upcomingFeatures.premiumFeatures.title', descriptionKey: 'homePage.upcomingFeatures.premiumFeatures.description' },
  ];

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-pink-900 text-white">
      <Nav
        authToken={authToken}
        minimal={false}
        setShowModal={setShowModal}
        showModal={showModal}
        setIsSignUp={setIsSignUp}
        handleLogout={() => handleAuthClick(false)}
        logoVersion='white'
      />
    
      <main ref={mainRef} className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <section className="flex flex-col justify-center items-center text-center py-32 sm:py-48">
          <motion.h1 
            variants={fadeInUpVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.6 }}
            className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6"
          >
            {t('homePage.title')}{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500">
              {t('homePage.brandName.first')}
              {t('homePage.brandName.second')}
            </span>
          </motion.h1>
          <motion.p 
            variants={fadeInUpVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl sm:text-2xl mb-8 max-w-2xl mx-auto"
          >
            {t('homePage.subtitle')}
          </motion.p>
          <motion.button 
            variants={fadeInUpVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.6, delay: 0.4 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleAuthClick(true)}
            className="bg-white text-purple-600 hover:bg-purple-100 px-8 py-4 rounded-full text-lg sm:text-xl font-semibold transition duration-300 shadow-lg"
          >
            {authToken ? t('homePage.logout') : t('homePage.cta')}
          </motion.button>
        </section>
        
        {/* Features Section */}
        <section className="pt-4 pb-32 sm:pt-16 sm:pb-24">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12">{t('homePage.features.title')}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <motion.div 
                key={index}
                variants={fadeInUpVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 shadow-xl"
              >
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-gradient-to-br from-pink-500 to-purple-600 rounded-full flex items-center justify-center mr-4">
                    <feature.icon size={24} className="text-white" />
                  </div>
                  <h3 className="text-xl sm:text-2xl font-semibold">{t(feature.titleKey)}</h3>
                </div>
                <p className="text-gray-300">{t(feature.descriptionKey)}</p>
              </motion.div>
            ))}
          </div>
        </section>

        <section className="py-24 sm:py-28">
  <motion.div
    variants={fadeInUpVariants}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    transition={{ duration: 0.8 }}
    className="bg-transparent text-center"
  >
    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6">{t('homePage.demoVideo.title')}</h2>
    <p className="text-base sm:text-lg md:text-xl mb-6 sm:mb-8 max-w-2xl mx-auto">{t('homePage.demoVideo.description')}</p>
    <div 
      className="relative max-w-3xl mx-auto rounded-xl overflow-hidden shadow-2xl cursor-pointer"
      onClick={togglePlayPause}
    >
              <div className="aspect-w-16 aspect-h-9">
                <video
                  ref={videoRef}
                  className="w-full h-full object-contain"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src={demo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              {!isPlaying && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
                  <Play size={64} className="text-white opacity-75" />
                </div>
              )}
    </div>
  </motion.div>
</section>
        
        {/* How It Works Section - Curved Timeline with Floating Cards */}
        <section className="py-16 sm:py-24 relative overflow-hidden">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative">
              {/* Curved line */}
              <svg className="absolute left-0 w-full h-full hidden md:block" preserveAspectRatio="none" viewBox="0 0 100 100">
                <path d="M0,50 Q25,0 50,50 T100,50" fill="none" stroke="url(#gradient)" strokeWidth="2" />
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#ec4899" />
                    <stop offset="100%" stopColor="#8b5cf6" />
                  </linearGradient>
                </defs>
              </svg>
              
              {howItWorks.map((step, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                  className={`flex flex-col-reverse md:flex-row items-center justify-center mb-24 relative ${
                    index % 2 === 0 ? 'md:flex-row-reverse' : ''
                  }`}
                >
                  <div className={`w-full md:w-5/12 mt-8 md:mt-0 ${index % 2 === 0 ? 'md:pl-8' : 'md:pr-8'}`}>
                    <motion.div 
                      className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 shadow-xl"
                      whileHover={{ scale: 1.05, rotate: index % 2 === 0 ? 2 : -2 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <h3 className="text-2xl sm:text-3xl font-semibold mb-4">{t(step.titleKey)}</h3>
                      <p className="text-gray-300 text-lg">{t(step.descriptionKey)}</p>
                    </motion.div>
                  </div>
                  <div className="w-24 h-24 bg-gradient-to-br from-pink-500 to-purple-600 rounded-full flex items-center justify-center flex-shrink-0 z-10 shadow-lg">
                    <step.icon size={48} className="text-white" />
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

       

        {/* Upcoming Features Section */}
        <section className="py-16 sm:py-24">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12">{t('homePage.upcomingFeatures.title')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {upcomingFeatures.map((feature, index) => (
              <motion.div 
                key={index}
                variants={fadeInUpVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-6 shadow-xl text-center"
              >
                <div className="w-16 h-16 bg-gradient-to-br from-blue-500 to-purple-600 rounded-full flex items-center justify-center mx-auto mb-4">
                  <feature.icon size={32} className="text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{t(feature.titleKey)}</h3>
                <p className="text-gray-300">{t(feature.descriptionKey)}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-16 sm:py-24 text-center">
          <motion.div 
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="rounded-3xl p-8 sm:p-12 "
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-6">{t('homePage.ctaSection.title')}</h2>
            <p className="text-lg sm:text-xl mb-8 max-w-2xl mx-auto">{t('homePage.ctaSection.description')}</p>
            <button 
              onClick={() => handleAuthClick(true)}
              className="bg-white text-purple-600 hover:bg-purple-100 px-8 py-4 rounded-full text-lg sm:text-xl font-semibold transition duration-300 shadow-lg"
            >
              {authToken ? t('homePage.ctaSection.loggedInButton') : t('homePage.ctaSection.button')}
            </button>
          </motion.div>
        </section>
      </main>
      
      <footer className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-md text-white p-6 mt-16">
  <div className="container mx-auto flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-6">
    <p className="text-xs">&copy; 2024 {t('homePage.footer')}</p>
    <div className="w-px h-4 bg-white bg-opacity-30 hidden sm:block"></div>
    <Link to="/terms-of-service" className="text-xs hover:underline transition-all">
      {t('homePage.termsOfService')}
    </Link>
    <div className="w-px h-4 bg-white bg-opacity-30 hidden sm:block"></div>
    <Link to="/privacy-policy" className="text-xs hover:underline transition-all">
    {t('settings.privacyPolicy')}
    </Link>
  </div>
</footer>
      {showModal && (
        <AuthModal setShowModal={setShowModal} isSignUp={isSignUp} />
      )}
    </div>
  );
};

export default HomePage;