import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clearNewMatch, incrementMatchIndex, closeNewMatchesModal } from '../../redux/slices/userSlice';

const NewMatchesModal = ({ match, totalMatches, currentMatchIndex }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.profile);
  const userId = useSelector(state => state.auth.userId);

  const handleSendMessage = () => {
    if (match && match.user_id) {
      dispatch(clearNewMatch({ userId, matchedUserId: match.user_id }));
      navigate('/dashboard/messages');
    }
  };
  
  const handleNextMatch = () => {
    if (match && match.user_id) {
      dispatch(clearNewMatch({ userId, matchedUserId: match.user_id }));
      if (currentMatchIndex < totalMatches - 1) {
        dispatch(incrementMatchIndex());
      } else {
        dispatch(closeNewMatchesModal());
        navigate('/dashboard/discover');
      }
    }
  };

  if (!match) {
    dispatch(closeNewMatchesModal());
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className="p-6 rounded-lg shadow-xl max-w-sm w-full text-white text-center">
      <h2 className="text-3xl font-bold mb-4 italic">{t('matches.itsAMatch')}</h2>
        <p className="mb-2">{t('matches.mutualLike', { name: match.first_name })}</p>
        <p className="mb-6 text-sm">{t('matches.matchCount', { current: currentMatchIndex + 1, total: totalMatches })}</p>
        <div className="flex justify-center space-x-8 mb-8">
          <img 
            src={currentUser && currentUser.media && currentUser.media[0] ? currentUser.media[0].url : '/default-avatar.png'} 
            alt={currentUser ? currentUser.first_name : 'You'} 
            className="w-24 h-24 rounded-full object-cover border-2 border-white"
          />
          <img 
            src={match.media && match.media[0] ? match.media[0].url : '/default-avatar.png'} 
            alt={match.first_name || 'Match'} 
            className="w-24 h-24 rounded-full object-cover border-2 border-white"
          />
        </div>
        <button 
          onClick={handleSendMessage} 
          className="w-full py-3 bg-gradient-to-r from-blue-500 to-pink-500 text-white rounded-full font-bold mb-3 hover:opacity-90 transition-opacity"
        >
          {t('matches.sendMessage')}
        </button>
        <button 
          onClick={handleNextMatch} 
          className="w-full py-3 bg-gradient-to-r from-pink-300 to-blue-300 border border-white text-white rounded-full font-bold hover:bg-white hover:text-gray-900 transition-colors"
        >
          {currentMatchIndex < totalMatches - 1 ? t('matches.nextMatch') : t('matches.keepSwiping')}
        </button>
      </div>
    </div>
  );
};

export default NewMatchesModal;