import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Camera, Edit2, MapPin, Briefcase, GraduationCap, Heart, Music, Film, Book, SaveIcon, ChevronLeft, ChevronRight, Video } from 'lucide-react';
import { setUserProfile, setUserLocation, fetchFullProfile } from '../../redux/slices/userSlice';
import ImageVideoUploader from '../ImageVideoUploader';
import { useLocation } from '../hooks/useLocation';
import { useTranslation } from 'react-i18next';
import { getLocationString } from '../../utils/locationUtils';
import api from '../../utils/api';

const ProfileTab = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const user = useSelector(state => state.user.profile);
  const userId = useSelector(state => state.auth.userId);
  const location = useLocation();

  const [locationString, setLocationString] = useState('');
  const [profileData, setProfileData] = useState({
    first_name: '',
    dob_day: '',
    dob_month: '',
    dob_year: '',
    gender_identity: '',
    gender_interest: '',
    about: '',
    occupation: '',
    education: '',
  });
  const [media, setMedia] = useState([]);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (userId) {
      dispatch(fetchFullProfile(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (user) {
      setProfileData(prevData => ({
        ...prevData,
        ...user,
      }));
      const newMedia = Array.isArray(user.media) 
        ? user.media.map((item, index) => ({
            ...item,
            id: `media-${index}`,
            order: item.order || index
          }))
        : [];
      setMedia(newMedia.sort((a, b) => a.order - b.order));
    }
  }, [user]);

  useEffect(() => {
    if (location) {
      updateLocationString(location);
    }
  }, [location]);

  const updateLocationString = async (loc) => {
    if (loc && loc.coordinates) {
      const locString = await getLocationString(loc);
      setLocationString(locString);
    } else {
      setLocationString('Location not set');
    }
  };

  const validateInput = (name, value) => {
    switch (name) {
      case 'first_name':
        return value.length <= 50 ? '' : 'Name must be 50 characters or less';
      case 'about':
        return value.length <= 500 ? '' : 'Bio must be 500 characters or less';
      case 'occupation':
      case 'education':
        return value.length <= 100 ? '' : 'Must be 100 characters or less';
      default:
        return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let processedValue = value;


      const error = validateInput(name, value);
      setErrors(prev => ({ ...prev, [name]: error }));
    

    setProfileData(prevData => ({ ...prevData, [name]: processedValue }));
  };

  const handleMediaUpload = (uploadedMedia) => {
    if (uploadedMedia && uploadedMedia.length > 0) {
      setMedia(uploadedMedia.slice(0, 6)); // Limit to 6 media items
    }
  };

  const sanitizeData = (data) => {
    const sanitized = {};
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'string') {
        sanitized[key] = value.replace(/[<>]/g, '');
      } else if (Array.isArray(value)) {
        sanitized[key] = value.map(item => typeof item === 'string' ? item.replace(/[<>]/g, '') : item);
      } else {
        sanitized[key] = value;
      }
    }
    return sanitized;
  };

  const handleSaveProfile = async () => {
    if (Object.values(errors).some(error => error !== '')) {
      alert('Please correct the errors before saving.');
      return;
    }
  
    try {
      const sanitizedData = sanitizeData(profileData);
      const dataToSend = {
        ...sanitizedData,
        media: media.map((item, index) => ({
          url: item.url,
          type: item.type,
          order: index
        })).slice(0, 6),
        location: location,
      };
  
      const response = await api.put('/users/user', dataToSend);
      if (response.status === 200) {
        dispatch(setUserProfile(response.data));
        if (response.data.location) {
          dispatch(setUserLocation(response.data.location));
        }
        setIsEditingProfile(false);
      }
    } catch (error) {
      alert('Failed to update profile. Please try again.');
    }
  };

  const nextMedia = () => {
    setCurrentMediaIndex((prevIndex) => 
      prevIndex === media.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevMedia = () => {
    setCurrentMediaIndex((prevIndex) => 
      prevIndex === 0 ? media.length - 1 : prevIndex - 1
    );
  };

  const age = profileData.dob_year ? new Date().getFullYear() - parseInt(profileData.dob_year) : '';

  return (
    <div className="w-full max-w-4xl mx-auto bg-white rounded-3xl shadow-xl overflow-hidden pb-20 md:pb-0">
      <div className="relative h-64 sm:h-96 md:h-[70vh]">
        {media.length > 0 ? (
          <>
            {media[currentMediaIndex].type === 'image' ? (
              <img 
                src={media[currentMediaIndex].url} 
                alt={`Profile ${currentMediaIndex + 1}`} 
                className="w-full h-full object-cover"
              />
            ) : (
              <video 
                src={media[currentMediaIndex].url} 
                className="w-full h-full object-cover"
                controls
              />
            )}
            {media.length > 1 && (
              <>
                <button 
                  onClick={prevMedia} 
                  className="absolute left-2 sm:left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1 sm:p-2 transition-opacity duration-300 hover:bg-opacity-75"
                >
                  <ChevronLeft size={20} />
                </button>
                <button 
                  onClick={nextMedia} 
                  className="absolute right-2 sm:right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1 sm:p-2 transition-opacity duration-300 hover:bg-opacity-75"
                >
                  <ChevronRight size={20} />
                </button>
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                  {media.map((_, index) => (
                    <div 
                      key={index} 
                      className={`w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full transition-all duration-300 ${index === currentMediaIndex ? 'bg-white scale-125' : 'bg-gray-400'}`}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <Camera size={36} className="text-gray-400" />
          </div>
        )}
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4 sm:p-6">
          <h2 className="text-2xl sm:text-4xl font-bold text-white">{profileData.first_name}, {age}</h2>
          <p className="text-lg sm:text-xl text-white">{profileData.occupation}</p>
          <p className="text-base sm:text-lg text-white">{locationString}</p>
        </div>
      </div>
  
      <div className="p-4 sm:p-6 md:p-10">
        <div className="mb-6 sm:mb-8 flex justify-end">
          <button
            className={`px-4 sm:px-6 py-2 sm:py-3 rounded-full flex items-center ${
              isEditingProfile 
                ? 'bg-green-500 hover:bg-green-600' 
                : 'bg-blue-500 hover:bg-blue-600'
            } text-white transition duration-300 text-sm sm:text-base`}
            onClick={() => isEditingProfile ? handleSaveProfile() : setIsEditingProfile(true)}
          >
            {isEditingProfile ? (
              <>
                <SaveIcon size={18} className="mr-2" />
                {t('profile.saveProfile')}
              </>
            ) : (
              <>
                <Edit2 size={18} className="mr-2" />
                {t('profile.editProfile')}
              </>
            )}
          </button>
        </div>
  
        {isEditingProfile ? (
          <div className="space-y-6 sm:space-y-8">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">{t('profile.name')}</label>
              <input
                type="text"
                name="first_name"
                value={profileData.first_name}
                onChange={handleInputChange}
                maxLength={50}
                className="w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm sm:text-base"
              />
              {errors.first_name && <p className="text-red-500 text-xs sm:text-sm mt-1">{errors.first_name}</p>}
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">{t('profile.about')}</label>
              <textarea
                name="about"
                value={profileData.about}
                onChange={handleInputChange}
                rows="4"
                maxLength={500}
                className="w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm sm:text-base"
              ></textarea>
              {errors.about && <p className="text-red-500 text-xs sm:text-sm mt-1">{errors.about}</p>}
            </div>
  
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Occupation</label>
                <input
                  type="text"
                  name="occupation"
                  value={profileData.occupation}
                  onChange={handleInputChange}
                  maxLength={100}
                  className="w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm sm:text-base"
                />
                {errors.occupation && <p className="text-red-500 text-xs sm:text-sm mt-1">{errors.occupation}</p>}
              </div>
  
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Education</label>
                <input
                  type="text"
                  name="education"
                  value={profileData.education}
                  onChange={handleInputChange}
                  maxLength={100}
                  className="w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm sm:text-base"
                />
                {errors.education && <p className="text-red-500 text-xs sm:text-sm mt-1">{errors.education}</p>}
              </div>
            </div>
  
           
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-4">Photos and Video (max 6)</label>
              <ImageVideoUploader 
                onMediaUpload={handleMediaUpload} 
                maxMedia={6}
                initialMedia={media}
                userData={{
                  first_name: profileData.first_name,
                  age: age,
                  occupation: profileData.occupation,
                  location: locationString
                }}
              />
            </div>
          </div>
        ) : (
          <div className="space-y-6 sm:space-y-8">
             <div>
              <h3 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">{t('profile.about')}</h3>
              <p className="text-gray-700 leading-relaxed text-sm sm:text-base">{profileData.about || t('profile.noBio')}</p>
            </div>
  
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div className="flex items-center text-gray-600">
                <Briefcase size={20} className="mr-3" />
                <span className="text-base sm:text-lg">{profileData.occupation || t('profile.occupationNotSet')}</span>
              </div>
  
              <div className="flex items-center text-gray-600">
                <GraduationCap size={20} className="mr-3" />
                <span className="text-base sm:text-lg">{profileData.education || t('profile.educationNotSet')}</span>
              </div>
            </div>
<div>

</div>

<div>
  <h3 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">{t('profile.photosAndVideo')}</h3>
  <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-2 sm:gap-4">
    {media.map((item, index) => (
      <div key={index} className="relative aspect-square rounded-lg overflow-hidden">
        {item.type === 'image' ? (
          <img
            src={item.url}
            alt={t('profile.userMediaAlt', { index: index + 1 })}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <Video size={24} className="text-gray-500" />
          </div>
        )}
      </div>
    ))}
  </div>
</div>
</div>
)}
</div>
</div>
);
};

export default ProfileTab;