import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuth, clearAuth } from '../../redux/slices/authSlice';
import api from '../../utils/api';

const useAuthToken = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('authToken');
      const userId = localStorage.getItem('userId');
      if (token && userId) {
        try {
          await api.get('/auth/verify-token');
          dispatch(setAuth({ authToken: token, userId }));
        } catch (error) {
          console.error('Token verification failed:', error);
          dispatch(clearAuth());
        }
      } else {
        dispatch(clearAuth());
      }
      setLoading(false);
    };

    verifyToken();
  }, [dispatch]);

  return loading;
};

export default useAuthToken;