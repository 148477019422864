import React, { useState, useEffect, useRef} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlusCircle, X, Move, Eye, Video } from 'lucide-react';
import ImagePreview from './ImagePreview';
import api from '../utils/api';

const ImageVideoUploader = ({ onMediaUpload, maxMedia = 6, userData, initialMedia = [] }) => {
  const [media, setMedia] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const fileInputRef = useRef(null);

  

  useEffect(() => {
    // This effect should only run once on component mount
    setMedia(initialMedia.map((item, index) => ({ ...item, id: `media-${index}` })));
  }, []);


  const handleMediaChange = async (e) => {
    const newFiles = Array.from(e.target.files);

    const maxFileSize = 5 * 1024 * 1024; // 5 MB

    const oversizedFiles = newFiles.filter(file => file.size > maxFileSize);
    if (oversizedFiles.length > 0) {
      alert(`The following files are too large: ${oversizedFiles.map(f => f.name).join(', ')}. Maximum file size is 5 MB.`);
      return;
    }
    if (media.length + newFiles.length <= maxMedia) {
      try {
        const uploadedMedia = await Promise.all(newFiles.map(uploadFile));
        const updatedMedia = [
          ...media,
          ...uploadedMedia.filter(Boolean).map((item, index) => ({
            ...item,
            id: `media-${media.length + index}`
          }))
        ];
        setMedia(updatedMedia);
        onMediaUpload(updatedMedia);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.error('Error uploading files:', error);
        alert('There was an error uploading your files. Please try again.');
      }
    } else {
      alert(`You can only upload up to ${maxMedia} media files.`);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    const isVideo = file.type.startsWith('video/');
    formData.append(isVideo ? 'video' : 'file', file);
  
    try {
      const response = await api.post(isVideo ? '/videos/upload-video' : '/users/upload', formData);
      return { url: response.data.url, type: isVideo ? 'video' : 'image' };
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  const removeMedia = (index) => {
    const imageCount = media.filter(item => item.type === 'image').length;
    const isRemovingImage = media[index].type === 'image';
  
    if (imageCount > 1 || (imageCount === 1 && !isRemovingImage)) {
      const updatedMedia = media.filter((_, i) => i !== index);
      setMedia(updatedMedia);
      onMediaUpload(updatedMedia);
    } else if (isRemovingImage && imageCount === 1) {
      alert("You must have at least one image in your profile.");
    } else {
      const updatedMedia = media.filter((_, i) => i !== index);
      setMedia(updatedMedia);
      onMediaUpload(updatedMedia);
    }
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newMedia = Array.from(media);
    const [reorderedItem] = newMedia.splice(result.source.index, 1);
    
    // Prevent video from being moved to the first position
    if (reorderedItem.type === 'video' && result.destination.index === 0) {
      // If trying to move video to first position, place it second instead
      newMedia.splice(1, 0, reorderedItem);
    } else {
      newMedia.splice(result.destination.index, 0, reorderedItem);
    }

    const updatedMedia = newMedia.map((item, index) => ({
      ...item,
      order: index
    }));

    setMedia(updatedMedia);
    onMediaUpload(updatedMedia);
  };

  const openPreview = (index) => {
    setPreviewIndex(index);
    setPreviewOpen(true);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="media" direction="horizontal">
          {(provided) => (
            <div 
              {...provided.droppableProps} 
              ref={provided.innerRef} 
              className="flex flex-wrap gap-4"
            >
              {media.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="relative w-24 h-24"
                    >
                      {item.type === 'image' ? (
                        <img
                          src={item.url}
                          alt={`Uploaded ${index + 1}`}
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <div className="w-full h-full bg-gray-200 flex items-center justify-center rounded-md">
                          <Video size={32} className="text-gray-500" />
                        </div>
                      )}
                      {media.length > 1 && (
  <button
    onClick={() => removeMedia(index)}
    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
  >
    <X size={16} />
  </button>
)}
                      <div {...provided.dragHandleProps} className="absolute bottom-1 right-1 bg-gray-800 text-white rounded-full p-1">
                        <Move size={16} />
                      </div>
                      <button
                        onClick={() => openPreview(index)}
                        className="absolute top-1 left-1 bg-blue-500 text-white rounded-full p-1 hover:bg-blue-600 transition-colors"
                      >
                        <Eye size={16} />
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {media.length < maxMedia && (
                <label className="w-24 h-24 flex items-center justify-center bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 transition-colors">
                  <input
                  ref={fileInputRef}
                    type="file"
                    accept="image/*,video/*"
                    onChange={handleMediaChange}
                    className="hidden"
                    multiple
                  />
                  <PlusCircle className="text-gray-400" size={32} />
                </label>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {previewOpen && media.length > 0 && (
        <ImagePreview
          media={media}
          onClose={() => setPreviewOpen(false)}
          onRemove={(index) => {
            removeMedia(index);
            if (media.length === 1) {
              setPreviewOpen(false);
            }
          }}
          userData={userData}
        />
      )}
    </>
  );
};

export default ImageVideoUploader;