import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateUserPreferences, setUserLocation, logoutUser, fetchFullProfile } from '../../redux/slices/userSlice';
import { LogOut, Shield, Bell, HelpCircle, Sliders, Globe, Eye, EyeOff, Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import LocationSelector from '../LocationSelector';
import LanguageSwitcher from '../LanguageSwitcher';
import MapLocationSelector from '../MapLocationSelector';
import { useLocation } from '../hooks/useLocation';
import { getSocket } from '../../utils/socketManager';
import api from '../../utils/api';

const SettingsTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const user = useSelector(state => state.user.profile);
  const userId = useSelector(state => state.auth.userId);
  const preferences = useSelector(state => state.user.profile?.preferences || {});
  const reduxLocation = useLocation();
  const [location, setLocation] = useState(null);
  const [isUnknownLocation, setIsUnknownLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(90);
  const [timerActive, setTimerActive] = useState(false);

  const [activeSection, setActiveSection] = useState('general');
  const [formData, setFormData] = useState({
    ageRange: { min: 18, max: 99 },
    maxDistance: 50,
    gender_interest: 'everyone',
    showOnlineStatus: false,
    showInSearchResults: true,
    emailNotifications: false,
    pushNotifications: false,
  });

  const [resetStage, setResetStage] = useState('initial'); // 'initial', 'emailSent', 'tokenVerified'
  const [resetEmail, setResetEmail] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [resetError, setResetError] = useState('');
  const [resetSuccess, setResetSuccess] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const sanitizeInput = (input) => {
    return input.replace(/[&<>"']/g, function (m) {
      return {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
      }[m]
    });
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchFullProfile(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (reduxLocation) {
      setLocation(reduxLocation);
    }
  }, [reduxLocation]);

  useEffect(() => {
    if (preferences) {
      setFormData(prevData => ({
        ...prevData,
        ...preferences,
        showInSearchResults: preferences.showInSearchResults !== false,
      }));
    }
  }, [preferences]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => {
      const newData = { ...prevData };
      if (type === 'checkbox') {
        newData[name] = checked;
      } else if (name === 'ageRange.min' || name === 'ageRange.max') {
        const [parent, child] = name.split('.');
        let newValue = parseInt(value, 10);
        if (child === 'min') {
          newValue = Math.min(newValue, prevData.ageRange.max - 1);
        } else if (child === 'max') {
          newValue = Math.max(newValue, prevData.ageRange.min + 1);
        }
        newData.ageRange = { ...prevData.ageRange, [child]: newValue };
      } else if (name === 'maxDistance') {
        newData[name] = parseInt(value, 10);
      } else {
        newData[name] = value;
      }
      return newData;
    });
  };

  const handleLocationChange = (newLocation) => {
    dispatch(setUserLocation(newLocation)).then(() => {
      setLocation(newLocation);
    }).catch(() => {
      alert(t('settings.locationUpdateError'));
    });
    setIsUnknownLocation(false);
  };
  
  const handleMapLocationChange = (newLocation) => {
    if (newLocation && newLocation.coordinates) {
      dispatch(setUserLocation(newLocation)).then(() => {
        setLocation(newLocation);
      }).catch(() => {
        alert(t('settings.locationUpdateError'));
      });
      setIsUnknownLocation(false);
    } else {
      setIsUnknownLocation(true);
    }
  };

  const saveSettings = async () => {
    if (!user || !user.user_id) {
      alert(t('settings.userError'));
      return;
    }
    if (isUnknownLocation || !location) {
      alert(t('settings.invalidLocationError'));
      return;
    }
    const dataToSend = {
      userId: user.user_id,
      preferences: {
        ageRange: formData.ageRange,
        maxDistance: formData.maxDistance,
        gender_interest: formData.gender_interest,
        showOnlineStatus: formData.showOnlineStatus,
        showInSearchResults: formData.showInSearchResults,
        emailNotifications: formData.emailNotifications,
        pushNotifications: formData.pushNotifications,
      },
      location: location,
    };
    try {
      const resultAction = await dispatch(updateUserPreferences(dataToSend));
      if (updateUserPreferences.fulfilled.match(resultAction)) {
        alert(t('settings.updateSuccess'));
      } else {
        throw new Error(t('settings.updateFailed'));
      }
    } catch (error) {
      alert(t('settings.updateError'));
    }
  };
  useEffect(() => {
    let timer;
    if (timerActive && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setResetStage('initial');
      setResetEmail('');
      setResetToken('');
      setResetError(t('settings.tokenExpired'));
    }
    return () => clearInterval(timer);
  }, [timerActive, timeRemaining, t]);

  const handleInitiateReset = async (e) => {
    e.preventDefault();
    setResetError('');
    setResetSuccess('');
    setIsLoading(true);

    const sanitizedEmail = sanitizeInput(resetEmail);

    if (sanitizedEmail !== user.email) {
      setResetError(t('settings.emailMismatch'));
      setIsLoading(false);
      return;
    }

    try {
      await api.initiatePasswordReset(sanitizedEmail);
      setResetStage('emailSent');
      setResetSuccess(t('settings.resetEmailSent'));
      setTimeRemaining(90);
      setTimerActive(true);
    } catch (error) {
      setResetError(error.response?.data?.error || t('settings.resetInitiateError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyToken = async (e) => {
    e.preventDefault();
    setResetError('');
    setResetSuccess('');
    setIsLoading(true);

    const sanitizedToken = sanitizeInput(resetToken);

    try {
      await api.verifyResetToken(resetEmail, sanitizedToken);
      setResetStage('tokenVerified');
      setResetSuccess(t('settings.tokenVerified'));
      setTimerActive(false);
    } catch (error) {
      setResetError(error.response?.data?.error || t('settings.tokenVerificationError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setResetError('');
    setResetSuccess('');

    if (newPassword !== confirmNewPassword) {
      setResetError(t('settings.passwordMismatch'));
      return;
    }

    if (newPassword.length < 6) {
      setResetError(t('settings.passwordTooShort'));
      return;
    }

    try {
      await api.resetPassword(resetEmail, resetToken, newPassword);
      setResetSuccess(t('settings.passwordResetSuccess'));
      setResetStage('initial');
      setResetEmail('');
      setResetToken('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      setResetError(error.response?.data?.error || t('settings.passwordResetError'));
    }
  };


  const handleLogout = async () => {
    try {
      await api.post('/auth/logout');
      await dispatch(logoutUser()).unwrap();
      const socket = getSocket();
      if (socket) {
        socket.emit('user_logout', userId);
      }
      navigate('/');
    } catch (error) {
      alert(t('settings.logoutError'));
    }
  };

  const handleLanguageChange = async (lng) => {
    await i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  const NavItem = ({ id, icon: Icon, label }) => (
    <button 
      className={`flex items-center w-full p-2 rounded-lg ${activeSection === id ? 'bg-indigo-100 text-indigo-600' : 'text-gray-600 hover:bg-gray-100'}`}
      onClick={() => setActiveSection(id)}
    >
      <Icon className="mr-3" size={20} />
      {t(`settings.${label.toLowerCase()}`)}
    </button>
  );

  const ToggleSwitch = ({ name, label, checked, onChange }) => (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          name={name}
          className="sr-only"
          checked={checked}
          onChange={onChange}
        />
        <div className={`block w-14 h-8 rounded-full ${checked ? 'bg-indigo-600' : 'bg-gray-300'}`}></div>
        <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${checked ? 'transform translate-x-6' : ''}`}></div>
      </div>
      <div className="ml-3 text-gray-700 font-medium">
        {label}
      </div>
    </label>
  );

  return (
    <div className="bg-white dark:bg-gray-800 dark:text-white rounded-3xl shadow-xl overflow-hidden h-full flex flex-col">
      <h1 className="text-2xl font-bold p-4 sm:p-6 text-center border-b">{t('settings.title')}</h1>
      <div className="flex-grow flex flex-col md:flex-row overflow-hidden">
        <nav className="w-full md:w-1/3 bg-gray-50 p-4 md:p-6 flex flex-row md:flex-col md:justify-between overflow-x-auto md:overflow-x-visible">
          <div className="flex md:flex-col space-x-4 md:space-x-0 md:space-y-2">
            <NavItem id="general" icon={Sliders} label="General" />
            <NavItem id="privacy" icon={Shield} label="Privacy" />
            <NavItem id="security" icon={Shield} label="Security" />
            <NavItem id="notifications" icon={Bell} label="Notifications" />
            <NavItem id="help" icon={HelpCircle} label="Help" />
          </div>
          <button
            onClick={handleLogout}
            className="hidden md:flex w-full bg-red-500 text-white py-2 rounded-lg items-center justify-center hover:bg-red-600 transition-colors duration-200 mt-4"
          >
            <LogOut className="mr-2" size={20} />
            {t('settings.logout')}
          </button>
        </nav>
        <div className="flex-grow overflow-y-auto p-4 sm:p-6 pb-24 md:pb-6">
          <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">
            {t(`settings.${activeSection}`)}
          </h2>
          {activeSection === 'general' && (
            <div className="space-y-4 sm:space-y-6">
              <div className="flex items-center justify-between">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {t('settings.language')}
                </label>
                <LanguageSwitcher onChange={handleLanguageChange} />
              </div>
              <LocationSelector 
                initialLocation={location} 
                onLocationSelect={handleLocationChange} 
                customStyles={{
                  input: "w-full p-2 border border-gray-300 rounded-md text-gray-700",
                  suggestion: "bg-white border border-gray-300 text-gray-700 hover:bg-gray-100",
                  suggestionList: "bg-white border border-gray-300 mt-1"
                }}
              />
              <div className="relative z-0">
                <MapLocationSelector 
                  location={location}
                  onLocationChange={handleMapLocationChange}
                  isUnknownLocation={isUnknownLocation}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('settings.ageRange')}: {formData.ageRange.min} - {formData.ageRange.max}
                </label>
                <div className="flex items-center space-x-4">
                  <input
                    type="range"
                    name="ageRange.min"
                    min="18"
                    max="98"
                    value={formData.ageRange.min}
                    onChange={handleInputChange}
                    className="w-1/2 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
                  <input
                    type="range"
                    name="ageRange.max"
                    min="19"
                    max="99"
                    value={formData.ageRange.max}
                    onChange={handleInputChange}
                    className="w-1/2 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('settings.maxDistance')}: {formData.maxDistance} km
                </label>
                <input
                  type="range"
                  name="maxDistance"
                  min="1"
                  max="500"
                  value={formData.maxDistance}
                  onChange={handleInputChange}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {t('settings.interestedIn')}
                </label>
                <div className="flex flex-wrap gap-2">
                  {['man', 'woman', 'everyone'].map((option) => (
                    <button
                      key={option}
                      onClick={() => handleInputChange({ target: { name: 'gender_interest', value: option } })}
                      className={`px-4 py-2 rounded-full text-sm font-medium ${
                        formData.gender_interest === option
                          ? 'bg-indigo-600 text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      {t(`settings.${option}`)}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
{activeSection === 'privacy' && (
            <div className="space-y-4">
              <ToggleSwitch
                name="showInSearchResults"
                label={t('settings.showInSearchResults')}
                checked={formData.showInSearchResults}
                onChange={handleInputChange}
              />
            </div>
          )}
            {activeSection === 'security' && (
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">{t('settings.resetPassword')}</h3>
          {resetStage === 'initial' && (
            <form onSubmit={handleInitiateReset} className="space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {t('settings.resetPasswordDescription')}
              </p>
              <input
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                placeholder={t('settings.emailAddress')}
                className="w-full p-2 border rounded"
                required
              />
              <button
                type="submit"
                className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? <Loader className="animate-spin mr-2" size={20} /> : null}
                {t('settings.sendResetEmail')}
              </button>
            </form>
          )}
          {resetStage === 'emailSent' && (
            <form onSubmit={handleVerifyToken} className="space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {t('settings.enterResetToken')}
              </p>
              <input
                type="text"
                value={resetToken}
                onChange={(e) => setResetToken(e.target.value)}
                placeholder={t('settings.resetToken')}
                className="w-full p-2 border rounded"
                required
              />
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {t('settings.timeRemaining', { seconds: timeRemaining })}
              </p>
              <button
                type="submit"
                className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? <Loader className="animate-spin mr-2" size={20} /> : null}
                {t('settings.verifyToken')}
              </button>
            </form>
          )}
          {resetStage === 'tokenVerified' && (
            <form onSubmit={handleResetPassword} className="space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {t('settings.enterNewPassword')}
              </p>
              <div className="relative">
                <input
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder={t('settings.newPassword')}
                  className="w-full p-2 border rounded"
                  required
                  minLength={6}
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  className="absolute right-2 top-2 text-gray-500"
                >
                  {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  placeholder={t('settings.confirmNewPassword')}
                  className="w-full p-2 border rounded"
                  required
                  minLength={6}
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-2 top-2 text-gray-500"
                >
                  {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              <button
                type="submit"
                className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors"
              >
                {t('settings.resetPassword')}
              </button>
            </form>
          )}
          {resetError && <p className="text-red-500">{resetError}</p>}
          {resetSuccess && <p className="text-green-500">{resetSuccess}</p>}
        </div>
      )}
          {activeSection === 'notifications' && (
            <div className="space-y-4">
              <ToggleSwitch
                name="emailNotifications"
                label={t('settings.emailNotifications')}
                checked={formData.emailNotifications}
                onChange={handleInputChange}
              />
              <ToggleSwitch
                name="pushNotifications"
                label={t('settings.pushNotifications')}
                checked={formData.pushNotifications}
                onChange={handleInputChange}
              />
            </div>
          )}
          {activeSection === 'help' && (
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">{t('settings.faq')}</h3>
              <p>{t('settings.faqDescription')}</p>
              {/* Add more help content here */}
            </div>
          )}
          {activeSection !== 'help' && activeSection !== 'security' && (
            <div className="mt-6">
              <button
                onClick={saveSettings}
                className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors"
              >
                {t('settings.saveChanges')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsTab;