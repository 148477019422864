import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import { Map, EyeOff, Crosshair, Lock, Unlock, Info } from 'lucide-react';


import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapEvents = ({ onLocationChange, isMarkerMovementEnabled }) => {
    const map = useMapEvents({
      click(e) {
        if (isMarkerMovementEnabled) {
          checkAndUpdateLocation(e.latlng, onLocationChange);
        }
      },
    });
  
    return null;
  };

const checkAndUpdateLocation = async (latlng, onLocationChange) => {
  try {
    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse`, {
      params: {
        format: 'json',
        lat: latlng.lat,
        lon: latlng.lng,
      }
    });

    if (response.data && response.data.address) {
      // Location is valid (on land)
      onLocationChange({
        type: 'Point',
        coordinates: [latlng.lng, latlng.lat],
        displayName: response.data.display_name
      });
    } else {
      console.log("Invalid location selected");
      // Optionally, you can show a message to the user here
    }
  } catch (error) {
    console.error("Error checking location validity:", error);
  }
};
const InfoIcon = () => (
    <div className="relative inline-block ml-2">
      <div className="group">
        <Info className="w-5 h-5 text-gray-500 cursor-help" />
        <div className="absolute z-10 w-48 sm:w-56 md:w-64 px-3 py-2 text-xs sm:text-sm text-white bg-gray-900 rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-300 bottom-full left-1/2 transform -translate-x-1/2 mb-2 max-w-[90vw]">
          <p className="mb-1">Click the lock/unlock icon on the map to enable or disable marker movement.</p>
          <p>When unlocked, you can click on the map to change your location.</p>
          <div className="absolute w-2 h-2 bg-gray-900 transform rotate-45 left-1/2 -translate-x-1/2 -bottom-1"></div>
        </div>
      </div>
    </div>
  );

  const GradientButton = ({ showMap, onClick }) => (
    <div className="flex items-center">
      <button
        onClick={onClick}
        className={`
          inline-flex items-center justify-center
          bg-blue-500 hover:bg-blue-600
          text-white
          py-2 px-4
          rounded-full
          shadow-sm
          transition duration-300
          transform hover:scale-105
          focus:outline-none
          text-sm sm:text-base
        `}
      >
        {showMap ? (
          <>
            <EyeOff className="w-4 h-4 mr-2" />
            Hide Map
          </>
        ) : (
          <>
            <Map className="w-4 h-4 mr-2" />
            Show on Map
          </>
        )}
      </button>
      <InfoIcon />
    </div>
  );


const MapControls = ({ location }) => {
  const map = useMap();

  useEffect(() => {
    if (location && location.coordinates) {
      map.setView([location.coordinates[1], location.coordinates[0]], map.getZoom());
    }
  }, [location, map]);

  return null;
};

const MapLocationSelector = ({ location, onLocationChange, isUnknownLocation }) => {
    const [showMap, setShowMap] = useState(false);
    const [isMarkerMovementEnabled, setIsMarkerMovementEnabled] = useState(false);
  
    const mapOptions = {
      minZoom: 2,
      maxZoom: 18,
      maxBounds: [[-90, -180], [90, 180]],
      maxBoundsViscosity: 1.0,
      worldCopyJump: false,
    };
  
    const toggleMap = () => {
      setShowMap(!showMap);
    };
  
    const toggleMarkerMovement = () => {
      setIsMarkerMovementEnabled(!isMarkerMovementEnabled);
    };
  
    const MarkerToggleButton = () => (
      <button
        onClick={toggleMarkerMovement}
        className={`
          absolute top-2 right-2 z-[1000]
          bg-white p-2 rounded-full shadow-md
          transition duration-300 transform hover:scale-105
          focus:outline-none
        `}
        title={isMarkerMovementEnabled ? "Lock marker" : "Unlock marker"}
      >
        {isMarkerMovementEnabled ? <Lock className="w-5 h-5" /> : <Unlock className="w-5 h-5" />}
      </button>
    );
  
    return (
      <div className="space-y-4">
        <GradientButton showMap={showMap} onClick={toggleMap} />
  
        {isUnknownLocation && (
          <p className="text-red-500 text-sm">Please choose a valid location.</p>
        )}
  
        {showMap && (
          <div className="h-48 sm:h-64 md:h-96 relative">
            <MarkerToggleButton />
            <MapContainer 
              center={location && location.coordinates ? [location.coordinates[1], location.coordinates[0]] : [0, 0]} 
              zoom={3} 
              style={{ height: '100%', width: '100%' }}
              {...mapOptions}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                noWrap={true}
              />
              {location && location.coordinates && (
                <Marker position={[location.coordinates[1], location.coordinates[0]]} />
              )}
              <MapEvents onLocationChange={onLocationChange} isMarkerMovementEnabled={isMarkerMovementEnabled} />
              <MapControls location={location} />
            </MapContainer>
          </div>
        )}
      </div>
    );
  };
  
  export default MapLocationSelector;