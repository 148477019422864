import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setOnboardingCompleted, setUserLocation } from '../redux/slices/userSlice';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, AlertCircle, User, Heart, Briefcase, MapPin, Camera } from 'lucide-react';
import api from '../utils/api';
import Nav from '../components/Nav';
import ImageVideoUploader from '../components/ImageVideoUploader';
import LocationSelector from '../components/LocationSelector';

const OnBoarding = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth.userId);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    user_id: userId,
    first_name: "",
    dob_day: "",
    dob_month: "",
    dob_year: "",
    gender_identity: "man",
    gender_interest: "woman",
    about: "",
    matches: [],
    media: [],
    preferences: {
      ageRange: { min: 18, max: 99 },
      maxDistance: 500,
      gender_interest: "woman"
    },
    occupation: "",
    education: "",
    location: "",
    interests: []
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      user_id: userId
    }));
  }, [userId]);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  const requestAndUpdateLocation = useCallback(async () => {
    if ("geolocation" in navigator) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
  
        const { latitude, longitude } = position.coords;
        const newLocation = {
          type: 'Point',
          coordinates: [longitude, latitude]
        };
  
        setFormData(prevState => ({
          ...prevState,
          location: newLocation
        }));
  
        dispatch(setUserLocation(newLocation));
      } catch (error) {
        setErrors(prevErrors => ({ ...prevErrors, location: 'Failed to get location. Please enter manually.' }));
      }
    } else {
      setErrors(prevErrors => ({ ...prevErrors, location: 'Geolocation is not supported by this browser.' }));
    }
  }, [dispatch]);

  useEffect(() => {
    requestAndUpdateLocation();
  }, [requestAndUpdateLocation]);

  const validateStep = (currentStep) => {
    let stepErrors = {};
    switch (currentStep) {
      case 1:
        if (!formData.first_name.trim()) stepErrors.first_name = "First name is required";
        if (!formData.dob_day || !formData.dob_month || !formData.dob_year) {
          stepErrors.dob = "Full date of birth is required";
        } else {
          const day = parseInt(formData.dob_day);
          const month = parseInt(formData.dob_month);
          const year = parseInt(formData.dob_year);
          const currentYear = new Date().getFullYear();
          
          if (isNaN(day) || day < 1 || day > 31) stepErrors.dob_day = "Invalid day";
          if (isNaN(month) || month < 1 || month > 12) stepErrors.dob_month = "Invalid month";
          if (isNaN(year) || year < 1925 || year > currentYear - 18) {
            stepErrors.dob_year = "You must be at least 18 years old and born after 1925";
          }
        }
        break;
      case 2:
        if (!formData.about.trim()) stepErrors.about = "Tell us something about yourself";
        break;
      case 3:
        if (!formData.occupation.trim()) stepErrors.occupation = "Occupation is required";
        if (!formData.education.trim()) stepErrors.education = "Education is required";
        break;
      case 4:
        if (!formData.location || !formData.location.coordinates) stepErrors.location = "Location is required";
        break;
      case 5:
        if (formData.media.length === 0) stepErrors.media = "At least one profile picture is required";
        break;
      default:
        break;
    }
    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(step)) {
      setStep(prevStep => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    setStep(prevStep => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep(step)) return;

    const dataToSend = {
      ...formData,
      onboardingCompleted: true,
      preferences: {
        ...formData.preferences,
        gender_interest: formData.gender_interest
      },
      location: formData.location
    };

    try {
      const response = await api.put('/users/user', dataToSend);
      
      if (response.status === 200) {
        if (dataToSend.location) {
          localStorage.setItem('userLocation', JSON.stringify(dataToSend.location));
        }
        dispatch(setOnboardingCompleted(true));
        dispatch(setUserLocation(dataToSend.location)); // Dispatch this action
        navigate('/dashboard');
      }
    } catch (err) {
      setErrors({ submit: "An error occurred during submission. Please try again." });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageUpload = useCallback((uploadedMedia) => {
    setFormData((prevState) => ({
      ...prevState,
      media: uploadedMedia
    }));
  }, []);

  const handleLocationSelect = (location) => {
    setFormData(prevState => ({
      ...prevState,
      location: location
    }));
    setErrors(prevErrors => ({ ...prevErrors, location: undefined }));
  };

  const handleBirthdayChange = (e) => {
    const { name, value } = e.target;
    
    if (!/^\d*$/.test(value)) {
      return;
    }

    let newValue = value;
    if (name === 'dob_day' && parseInt(value) > 31) {
      newValue = '31';
    } else if (name === 'dob_month' && parseInt(value) > 12) {
      newValue = '12';
    }
  
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue
    }));
  };
 
  const inputClass = "w-full p-3 sm:p-4 bg-white border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-800 placeholder-gray-400 transition duration-200";
  const labelClass = "block text-sm font-semibold text-gray-700 mb-2";
  const errorClass = "text-red-500 text-sm mt-1";

  const SelectionButton = ({ value, selected, onChange, children }) => (
    <button
      type="button"
      className={`px-4 py-2 sm:px-6 sm:py-3 rounded-full text-sm font-semibold transition-all duration-300 ${
        selected
          ? 'bg-blue-600 text-white shadow-lg transform scale-105'
          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
      }`}
      onClick={() => onChange(value)}
    >
      {children}
    </button>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="space-y-4 sm:space-y-6"
          >
            <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-gray-800 flex items-center">
              <User className="inline-block mr-2" size={24} /> {t('onboarding.basicInfo')}
            </h3>
            <div>
              <label htmlFor="first_name" className={labelClass}>{t('onboarding.firstName')}</label>
              <input
                id="first_name"
                type='text'
                name="first_name"
                placeholder={t('onboarding.firstName')}
                required={true}
                value={formData.first_name}
                onChange={handleChange}
                className={inputClass}
              />
              {errors.first_name && <p className={errorClass}>{errors.first_name}</p>}
            </div>
            <div>
              <label className={labelClass}>{t('onboarding.birthday')}</label>
              <div className="flex space-x-2 sm:space-x-4">
                <div className="w-1/3">
                  <input
                    id="dob_day"
                    type="text"
                    name="dob_day"
                    placeholder="DD"
                    required={true}
                    value={formData.dob_day}
                    onChange={handleBirthdayChange}
                    maxLength="2"
                    className={`${inputClass} text-center`}
                  />
                </div>
                <div className="w-1/3">
                  <input
                    id="dob_month"
                    type="text"
                    name="dob_month"
                    placeholder="MM"
                    required={true}
                    value={formData.dob_month}
                    onChange={handleBirthdayChange}
                    maxLength="2"
                    className={`${inputClass} text-center`}
                  />
                </div>
                <div className="w-1/3">
                  <input
                    id="dob_year"
                    type="text"
                    name="dob_year"
                    placeholder="YYYY"
                    required={true}
                    value={formData.dob_year}
                    onChange={handleBirthdayChange}
                    maxLength="4"
                    className={`${inputClass} text-center`}
                  />
                </div>
              </div>
              {errors.dob && <p className={errorClass}>{errors.dob}</p>}
            </div>
            <div>
              <label className={labelClass}>{t('onboarding.gender')}</label>
              <div className="flex flex-wrap gap-2 sm:gap-4">
                {['man', 'woman', 'more'].map((gender) => (
                  <SelectionButton
                    key={gender}
                    value={gender}
                    selected={formData.gender_identity === gender}
                    onChange={(value) => handleChange({ target: { name: 'gender_identity', value } })}
                  >
                    {t(`onboarding.${gender}`)}
                  </SelectionButton>
                ))}
              </div>
            </div>
            <div>
              <label className={labelClass}>{t('onboarding.interestedIn')}</label>
              <div className="flex flex-wrap gap-2 sm:gap-4">
                {['man', 'woman', 'everyone'].map((gender) => (
                  <SelectionButton
                    key={gender}
                    value={gender}
                    selected={formData.gender_interest === gender}
                    onChange={(value) => handleChange({ target: { name: 'gender_interest', value } })}
                  >
                    {t(`onboarding.${gender}`)}
                  </SelectionButton>
                ))}
              </div>
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="space-y-4 sm:space-y-6"
          >
            <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-gray-800 flex items-center">
              <Heart className="inline-block mr-2" size={24} /> {t('onboarding.aboutMe')}
            </h3>
            <div>
              <label htmlFor="about" className={labelClass}>{t('onboarding.aboutMePrompt')}</label>
              <textarea
                id="about"
                name="about"
                required={true}
                placeholder={t('onboarding.aboutMePlaceholder')}
                value={formData.about}
                onChange={handleChange}
                className={`${inputClass} h-32 sm:h-40`}
              />
              {errors.about && <p className={errorClass}>{errors.about}</p>}
            </div>
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="space-y-4 sm:space-y-6"
          >
            <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-gray-800 flex items-center">
              <Briefcase className="inline-block mr-2" size={24} /> {t('onboarding.careerAndEducation')}
            </h3>
            <div>
              <label htmlFor="occupation" className={labelClass}>{t('onboarding.occupation')}</label>
              <input
                id="occupation"
                type="text"
                name="occupation"
                placeholder={t('onboarding.occupationPlaceholder')}
                value={formData.occupation}
                onChange={handleChange}
                className={inputClass}
              />
              {errors.occupation && <p className={errorClass}>{errors.occupation}</p>}
            </div>
            <div>
              <label htmlFor="education" className={labelClass}>{t('onboarding.education')}</label>
              <input
                id="education"
                type="text"
                name="education"
                placeholder={t('onboarding.educationPlaceholder')}
                value={formData.education}
                onChange={handleChange}
                className={inputClass}
              />
              {errors.education && <p className={errorClass}>{errors.education}</p>}
            </div>
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="space-y-4 sm:space-y-6"
          >
            <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-gray-800 flex items-center">
              <MapPin className="inline-block mr-2" size={24} /> {t('onboarding.location')}
            </h3>
            <div className="bg-gray-100 p-3 sm:p-4 rounded-lg">
            <LocationSelector
  initialLocation={formData.location}
  onLocationSelect={handleLocationSelect}
  customStyles={{
    input: "w-full p-2 border border-gray-300 rounded-md text-gray-700 bg-white",
    suggestionList: "bg-white border border-gray-300 mt-1 rounded-md shadow-lg",
    suggestion: "p-2 hover:bg-gray-100 cursor-pointer text-gray-700"
  }}
/>
            </div>
            {errors.location && <p className={errorClass}>{errors.location}</p>}
          </motion.div>
        );
      case 5:
        return (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="space-y-4 sm:space-y-6"
          >
            <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-gray-800 flex items-center">
              <Camera className="inline-block mr-2" size={24} /> {t('onboarding.profilePhoto')}
            </h3>
            <div className="bg-gray-100 p-3 sm:p-4 rounded-lg">
              <ImageVideoUploader 
                onMediaUpload={handleImageUpload} 
                maxImages={6} 
                userData={{
                  first_name: formData.first_name,
                  age: new Date().getFullYear() - formData.dob_year,
                  occupation: formData.occupation,
                  location: formData.location
                }}
              />
            </div>
            {errors.media && <p className={errorClass}>{errors.media}</p>}
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50">
      <Nav
        minimal={true}
        logoVersion='default'
        setShowModal={() => {}}
        showModal={false}
        variant="onboarding"
      />

      <div className="container mx-auto px-4 py-8 sm:py-12">
        <div className="bg-white rounded-3xl shadow-2xl overflow-hidden p-6 sm:p-8 max-w-3xl mx-auto">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center text-gray-800">{t('onboarding.createAccount')}</h2>

          <form onSubmit={handleSubmit} className="space-y-6 sm:space-y-8">
            <AnimatePresence mode="wait">
              {renderStep()}
            </AnimatePresence>

            <div className="flex flex-col sm:flex-row justify-between items-center mt-8 sm:mt-12 space-y-4 sm:space-y-0">
              {step > 1 && (
                <button
                  type="button"
                  onClick={handlePrevious}
                  className="flex items-center text-blue-600 hover:text-blue-800 px-4 py-2 sm:px-6 sm:py-3 rounded-full transition duration-300 w-full sm:w-auto justify-center sm:justify-start"
                >
                  <ChevronLeft className="mr-2" size={24} />
                  {t('general.previous')}
                </button>
              )}
              {step < 5 ? (
                <button
                  type="button"
                  onClick={handleNext}
                  className="flex items-center text-white bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-full transition duration-300 shadow-lg w-full sm:w-auto justify-center"
                >
                  {t('general.next')}
                  <ChevronRight className="ml-2" size={24} />
                </button>
              ) : (
                <button
                  type="submit"
                  className="flex items-center text-white bg-green-600 hover:bg-green-700 px-6 py-3 rounded-full transition duration-300 shadow-lg w-full sm:w-auto justify-center"
                >
                  {t('general.submit')}
                </button>
              )}
            </div>
          </form>

          <div className="mt-8 sm:mt-12 flex justify-center space-x-2 sm:space-x-3">
            {[1, 2, 3, 4, 5].map((stepNumber) => (
              <div
                key={stepNumber}
                className={`w-3 h-3 sm:w-4 sm:h-4 rounded-full transition-all duration-300 ${
                  step === stepNumber 
                    ? 'bg-blue-600 scale-125' 
                    : step > stepNumber
                    ? 'bg-blue-400'
                    : 'bg-gray-300'
                }`}
              />
            ))}
          </div>

          {errors.submit && (
            <div className="mt-6 p-4 bg-red-100 border-l-4 border-red-500 text-red-700 rounded-r-lg flex items-center">
              <AlertCircle className="mr-3 flex-shrink-0" size={24} />
              <p className="text-sm sm:text-base">{errors.submit}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnBoarding;