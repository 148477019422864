import React, { useState } from 'react';
import { X, ChevronLeft, ChevronRight, Trash2 } from 'lucide-react';

const ImagePreview = ({ media, onClose, onRemove, userData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!media || media.length === 0) return null;

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : media.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < media.length - 1 ? prevIndex + 1 : 0));
  };

  const currentMedia = media[currentIndex];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative w-full max-w-sm h-[80vh] overflow-hidden rounded-lg">
        {currentMedia.type === 'image' ? (
          <img
            src={currentMedia.url}
            alt={`Preview ${currentIndex + 1}`}
            className="w-full h-full object-cover"
          />
        ) : (
          <video
            src={currentMedia.url}
            controls
            className="w-full h-full object-cover"
          />
        )}
        {userData && (
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
            <h2 className="text-white text-2xl font-bold">
              {userData.first_name}{userData.age ? `, ${userData.age}` : ''}
            </h2>
            {userData.occupation && <p className="text-white text-sm">{userData.occupation}</p>}
            {userData.location && <p className="text-white text-sm">{userData.location}</p>}
          </div>
        )}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-white text-black rounded-full p-1 hover:bg-gray-200 transition-colors"
        >
          <X size={24} />
        </button>
        {media.length > 1 && (
          <>
            <button
              onClick={handlePrevious}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-1 hover:bg-gray-200 transition-colors"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={handleNext}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-1 hover:bg-gray-200 transition-colors"
            >
              <ChevronRight size={24} />
            </button>
          </>
        )}
               <div className="absolute top-2 left-1/2 transform -translate-x-1/2 bg-white text-black px-2 py-1 rounded-full text-sm">
          {currentIndex + 1} / {media.length}
        </div>
      </div>
    </div>
  );
};

export default ImagePreview;